export default {
  language_en: 'Englisch',
  language_de: 'Deutsch',
  Yes: 'Ja',
  No: 'Nein',
  and: 'und',
  required: 'verpflichtend',
  Instance: 'Instanz',
  Title: 'Titel',
  'Title (ID)': 'Titel (ID)',
  Active: 'Aktiv',
  active: 'aktiv',
  inactive: 'inaktiv',
  Field: 'Feld',
  'Image Title': 'Bildtitel',
  'List Type': 'Listentyp',
  New: 'Neu',
  'Search...': 'Suchen...',
  'Search term': 'Suchbegriff',
  Language: 'Sprache',
  Actions: 'Aktionen',
  Edit: 'Editieren',
  'You are editing': 'Du bearbeitest',
  'You are creating': 'Du erstellst',
  Close: 'Schließen',
  'Expand all': 'Alles aufklappen',
  'Collapse all': 'Alles zuklappen',
  Delete: 'Löschen',
  Assign: 'Zuweisen',
  'Sure to delete?': 'Wirklich löschen?',
  Confirm: 'Bestätigen',
  Create: 'Erstellen',
  'Last changed': 'Letzte Änderung',
  by: 'von',
  Usage: 'Verwendung',
  'Search Fields': 'Suchfelder',
  Category: 'Kategorie',
  Links: 'Suchbare Links',
  Pages: 'Landingpages',
  Products: 'Produkte',
  Product: 'Produkt',
  Manufacturers: 'Hersteller',
  Searchredirects: 'Such-Redirects',
  'Search Redirects': 'Such-Redirects',
  'Search Result': 'Suchergebnis',
  'Page Type': 'Seitentyp',
  'Create Stream': 'Stream erstellen',
  'Edit Stream': 'Stream bearbeiten',
  'Must fulfil these conditions': 'Alle Bedingungen müssen erfüllt sein',
  'Fulfil at least one condition':
    'Mindestens eine Bedingung muss erfüllt sein',
  'Influence the order of your results':
    'Beeinflusse die Reihenfolge deiner Ergebnisse',
  'General Configuration': 'Grundlegende Einstellungen',
  'Boost results based on statistically collected values':
    'Booste Ergebnisse basierend auf statistisch erhobenen Werten',
  'Where should the banner be displayed?':
    'Wo soll der Banner angezeigt werden?',
  'Should the banner be played out time-controlled?':
    'Soll der Banner zeitgesteuert ausgespielt werden?',
  'Add child': 'Kind hinzufügen',
  'Remove node': 'Eintrag entfernen',
  'Advanced configuration': 'Erweiterte Einstellungen',
  'Advanced Entry Configuration': 'Erweiterte Einstellungen',
  'Additional CSS classes': 'Zusätzliche CSS-Klassen',
  'Use only in navigation': 'Nur in Navigation verwenden',
  'Find a document and assign it to the menu entry':
    ' Suche ein Dokument und weise es dem Menü-Eintrag zu',
  'Menu successfully saved': 'Menü erfolgreich gespeichert',
  'Search preview': 'Suchvorschau',
  'Show all results': 'Alle Ergebnisse',
  menu_group_content: 'Inhalt',
  menu_group_search: 'Suche',
  menu_group_channels: 'Kanäle',
  menu_group_expert: 'Expertenmodus',
  menu_dashboard: 'Übersicht',
  menu_page_editor: 'Content Editor',
  menu_menu_editor: 'Menü-Editor',
  menu_banners: 'Werbebanner',
  menu_redirects: 'Weiterleitungen',
  menu_search_redirects: 'Such-Redirects',
  menu_search_fields: 'Suchfelder',
  menu_search_filters: 'Filter',
  menu_search_advertising: 'Werbeplätze',
  menu_search_synonyms: 'Synonyme',
  menu_search_antonyms: 'Antonyme',
  menu_search_boosting: 'Boosting',
  menu_search_searchable_links: 'Suchbare Links',
  menu_search_configuration: 'Konfiguration',
  menu_search_personalization: 'Personalisierung',
  menu_monitoring: 'Monitoring',
  menu_channels_feeds: 'Produkt-Feeds',
  menu_channels_mapping: 'Kategorie Mapping',
  menu_expert_component_editor: 'Komponent Editor',
  menu_expert_public_fields: 'Öffentliche Felder',
  menu_expert_data_inspector: 'Data Inspector',
  menu_expert_index_overview: 'Indexübersicht',
  menu_expert_index_management: 'Indexverwaltung',
  menu_category: 'Kategorie',
  menu_recommendation: 'Recommendations',
  menu_bundle: 'Smart Bundles',
  menu_streams: 'Streams',
  menu_experiment: 'A/B Experimente',
  banner_type_search: 'Suchergebnis',
  banner_type_category: 'Kategorieliste',
  banner_type_manufacturer: 'Herstellerliste',
  boostField_insert: 'Neue Produkte',
  boostField_sold: 'Anzahl der Verkäufe',
  boostField_basket: 'Häufig in den Warenkorb gelegt',
  boostField_view: 'Anzahl der Detailseitenaufrufe',
  boostField_revenue: 'Generierter Umsatz',
  boostField_rating: 'Gute Bewertungen',
  boostField_profit_margin: 'Hohe Marge',
  'Please insert title.': 'Bitte Titel vergeben.',
  'Please insert url.': 'Bitte Url vergeben.',
  'Please insert name.': 'Bitte Namen vergeben.',
  'Please choose field.': 'Bitte Feld wählen.',
  'Advertising Space': 'Werbeplätze',
  advertising_type_search: 'Suchergebnis',
  advertising_type_category: 'Kategorieliste',
  advertising_type_manufacturer: 'Herstellerliste',
  'Time-controlled': 'Zeitgesteuert',
  yes: 'ja',
  no: 'nein',
  'Active from': 'Aktiv von',
  'Active till': 'Aktiv bis',
  'Edit Advertising Space': 'Werbeplatz bearbeiten',
  'Create Advertising Space': 'Werbeplatz erstellen',
  'Should the sort order be considered?':
    'Soll die Sortierung berücksichtigt werden?',
  Frequency: 'Häufigkeit',
  'Alphabetically ascending': 'Alphabetisch aufsteigend',
  'Alphabetically descending': 'Alphabetisch absteigend',
  'Use Language-specific titles': 'Sprachspezifischen Titel nutzen',
  Sorting: 'Sortierung',
  'Show Number of found items?': 'Anzahl gefundener Artikel anzeigen?',
  'Show selected filters on top?': 'Ausgewählte Filter oben darstellen?',
  'Depends on': 'Hängt ab von',
  'Select field...': 'Feld auswählen...',
  'Select display...': 'Darstellung auswählen...',
  'Edit Filter': 'Filter bearbeiten',
  'Create Filter': 'Filter erstellen',
  'Should the advertising space be played out time-controlled?':
    'Soll der Werbeplatz zeitgesteuert ausgespielt werden?',
  Path: 'Pfad',
  Target: 'Ziel',
  'Redirect Code': 'Redirect-Code',
  'Redirect Target': 'Redirect-Ziel',
  'Edit Redirect': 'Redirect bearbeiten',
  'Create Redirect': 'Redirect erstellen',
  'Permanently (301)': 'Permanent (301)',
  'Temporary (302)': 'Temporär (302)',
  'Please insert path.': 'Bitte Pfad angeben.',
  'Please insert target.': 'Bitte Ziel angeben.',
  'Please select code.': 'Bitte Code auswählen.',
  Synonyms: 'Synonyme',
  'It may take up to 2 minutes until updates to your synonyms are reflected in all search results.':
    'Es kann bis zu 2 Minuten dauern bis Änderungen an den Synonymen in allen Suchen berücksichtigt werden.',
  'Please insert synonyms.': 'Bitte Synonyme angeben.',
  'Please insert name': 'Bitte Name angeben.',
  'Comma-separated list of synonyms': 'Komma-separierte Liste von Synonymen',
  Antonyms: 'Antonyme',
  'Please insert antonyms.': 'Bitte Antonyme angeben',
  product: 'Produkt',
  Type: 'Typ',
  page: 'Landingpages',
  url: 'Manuelle URL',
  'Manual URL': 'Manuelle URL',
  manufacturer: 'Hersteller',
  category: 'Kategorie',
  'Search words': 'Suchbegriffe',
  'Target type': 'Ziel-Typ',
  'Please select a type.': 'Bitte einen Ziel-Typ wählen.',
  'Please select a target.': 'Bitte ein Ziel wählen.',
  'Edit Search Redirect': 'Such-Redirect bearbeiten',
  'Create Search Redirect': 'Such-Redirect erstellen',
  'A/B Experiments': 'A/B Experimente',
  experiment_type_boosting: 'Ranking Mix',
  experiment_type_ml: 'Personalization',
  experiment_type_frontend: 'Frontend',
  experiment_type_recommendation: 'Recommendation',
  Finished: 'Beendet',
  Running: 'Läuft',
  Results: 'Ergebnisse',
  'Start experiment': 'Experiment starten',
  'Stop experiment': 'Experiment beenden',
  'Configure scenario': 'Szenario konfigurieren',
  'Do you really want to stop this experiment?':
    'Möchtest Du das Experiment wirklich stoppen?',
  'A stopped experiment cannot be started again and you need to create a new experiment.':
    'Ein gestopptes Experiment kann nicht erneut gestartet werden. Dafür muss dann ein neues Experiment angelegt werden.',
  Cancel: 'Abbrechen',
  Scenario: 'Szenario',
  'Create Experiment': 'Experiment erstellen',
  'Edit Experiment': 'Experiment bearbeiten',
  'Experiment started!': 'Experiment gestartet!',
  'It is recommended to activate each variant equally often (standard) but you can change the proportion for each variant. Make sure that a share of the traffic is assigned to the original version.':
    'Es ist empfohlen jede Variante gleich oft zu aktivieren (Standard) aber Du kannst den Anteil für jede Variante ändern. Stell sicher, dass ein Anteil des Traffics der originalen Version zugewiesen ist.',
  'How should the traffic be distributed?':
    'Wie soll der Traffic verteilt werden?',
  'What do you want to test?': 'Was willst Du testen?',
  Description: 'Beschreibung',
  'Traffic Scenario': 'Traffic Szenario',
  'Experiment successfully stopped.': 'Experiment erfolgreich beendet.',
  'Results loading...': 'Ergebnisse werden geladen...',
  Variant: 'Variante',
  Visits: 'Besuche',
  'Unique visitors': 'Eindeutige Besucher',
  'Per visit': 'Pro Besuch',
  'Detected effect': 'Erkannte Auswirkung',
  'Statistical significance': 'Statistische Signifikanz',
  experiment_nb_orders: 'Bestellungen',
  experiment_nb_conversions: 'Gekaufte Produkte',
  experiment_bounce_count: 'Absprünge',
  Bounces: 'Absprünge',
  experiment_nb_orders_revenue: 'Umsatz',
  experiment_sum_visit_length: 'Besuchsdauer',
  experiment_chart_nb_orders: 'Bestellungen pro Besuch',
  'Ecommerce Orders': 'Bestellungen pro Besuch',
  experiment_chart_nb_conversions: 'Gekaufte Produkte pro Besuch',
  'Total Conversions': 'Gekaufte Produkte pro Besuch',
  experiment_chart_bounce_count: 'Absprungrate in %',
  experiment_chart_nb_orders_revenue: 'Umsatz pro Besuch in €',
  'Ecommerce Orders Revenue': 'Umsatz pro Besuch',
  experiment_chart_sum_visit_length: 'Durchschnittliche Besuchsdauer in s',
  'Time on website': 'Durchschnittliche Besuchsdauer',
  Orders: 'Bestellungen',
  'Bought Products': 'Gekaufte Produkte',
  'Bounce Rate': 'Absprungrate',
  Revenue: 'Umsatz',
  'Average Visit Length': 'Besuchsdauer',
  'Experiment results': 'Experiment-Auswertung',
  'Could not load experiment results.':
    'Experiment-Auswertung konnte nicht geladen werden.',
  'Back to overview': 'Zurück zur Übersicht',
  'Please insert description.': 'Bitte Beschreibung angeben.',
  'Searchable Links': 'Suchbare Links',
  'Create Searchable Links': 'Suchbare Links erstellen',
  'Edit Searchable Links': 'Suchbare Links bearbeiten',
  Keywords: 'Schlüsselwörter',
  'External Link': 'Externer Link',
  'General settings': 'Allgemeine Einstellungen',
  'Document assignment': 'Dokumentzuweisung',
  'Where should the advertising space be displayed?':
    'Wo soll der Werbeplatz angezeigt werden?',
  Manufacturer: 'Hersteller',
  'What should be assigned to this advertising space?':
    'Was soll diesem Werbeplatz zugewiesen werden?',
  personalization_description1:
    'Datengetriebene Entscheidungen und maschinelles Lernen haben sich in der letzten Zeit als Methoden mit hohem Nutzen für vielerlei Anwendungen herausgestellt.',
  personalization_description2:
    'Wir integrieren maschinelles Lernen in unsere Suchapplikation und bieten so eine nutzerspezifische Suche.',
  personalization_description3:
    'Daten wie Browsertyp, Betriebssystem, Art des Gerätes, Uhrzeit, Jahreszeit oder der Ort lassen Rückschlüsse auf den Benutzer und dessen Bedürfnisse zu. So kann man sich leicht vorstellen, dass der Besitzer eines iPhone morgens um 10 Uhr in Berlin und der Besizer eines Windows 7 PCs abends um 18 Uhr in Niederbayern ganz verschiedene Wünsche bei der Eingabe des gleichen Suchbegriffs haben.',
  personalization_description4:
    'Das Beste am Maschinellen Lernen ist aber, dass man nicht mit Ideen oder Insiderwissen eine Kategorisierung der Benutzer vornimmt, da sich dies rein aus dem gesammelten Verhalten anderer Nutzer ergibt.',
  personalization_description5:
    'Vorraussetzung für diese Möglichkeit ist natürlich das Sammeln von Daten, dass vor dem Adaptieren der Suchergebnisse steht. Erst wenn statistisch signifikante Daten gesammelt wurden und die Werte für das Maschienenlernmodel konvergiert sind ist es sinnvoll die nutzerspezifische Suche zu aktivieren.',
  personalization_description6:
    'Mit dieser Suche ist es jetzt möglich, Besuchern aus verschiedenen Nutzergruppen, ein besseres auf sie zugeschnittenes Sucherlebnis zu präsentieren und dies wiederum wirkt sich auf die Conversion-Rate aus.',
  products: 'Produkte',
  categories: 'Kategorien',
  manufacturers: 'Hersteller',
  search: 'Suche',
  'Please insert identifier.': 'Bitte Identifier angeben',
  'Category-Mapping': 'Kategorie-Mapping',
  'Apply selection to all subcategories':
    'Auswahl für alle Unterkategorien übernehmen',
  'Reset all subcategories': 'Alle Unterkategorien zurücksetzen',
  'Show Category-Ids': 'Kategorie-IDs anzeigen',
  'Category-Mapping saved!': 'Kategorie-Mapping gespeichert!',
  Save: 'Speichern',
  Submit: 'Übernehmen',
  'What does the filter depend on?': 'Wovon hängt der Filter ab?',
  Script: 'Skript',
  'Please insert id.': 'Bitte ID angeben.',
  'Most frequent search terms': 'Häufigste Suchbegriffe',
  'Most frequent search terms without results':
    'Häufigste Suchbegriffe ohne Ergebnis',
  'Makaira Personalization disabled': 'Makaira Personalisierung deaktiviert',
  'Makaira Personalization enabled': 'Makaira Personalisierung aktiviert',
  'External personalization disabled': 'Externe Personalisierung deaktiviert',
  'External personalization using Econda enabled':
    'Externe Personalisierung mit Econda aktiviert',
  'External personalization using Custom API enabled':
    'Externe Personalisierung mit Custom API aktiviert',
  'Custom API endpoint': 'Benutzerdefinierter API-Endpunkt',
  'Save custom Api endpoint': 'Benutzerdefinierten Api-Endpunkt speichern',
  'Number of Requests': 'Anzahl Requests',
  'Search phrase': 'Suchphrase',
  'Last week': 'Letzte Woche',
  'Last 24h': 'Letzten 24h',
  'Last 4 weeks': 'Letzten 4 Wochen',
  'Public Fields': 'Öffentliche Felder',
  Feld: 'Feld',
  'Detail-Seiten': 'Detail-Seiten',
  'Produkt-Listen': 'Produkt-Listen',
  'Produkte auf Landing-Pages': 'Produkte auf Landing-Pages',
  Changed: 'Changed',
  'Edit Public Field': 'Öffentliches Feld bearbeiten',
  'Create Public Field': 'Öffentliches Feld erstellen',
  'Content Type': 'Content-Type',
  Stream: 'Stream',
  'Feed URL': 'Feed-URL',
  'Select the URL under which the feed should be accessible.':
    'Wähle aus, unter welcher URL der Feed erreichbar sein soll.',
  'Feed-Name': 'Feed-Name',
  Token: 'Token',
  'Feed-Token': 'Feed-Token',
  Filter: 'Filter',
  Common: 'Allgemein',
  'Export father items?': 'Vaterartikel exportieren?',
  'Export variants?': 'Varianten exportieren?',
  'Export father items only if no variants exist':
    'Vaterartikel nur exportieren, wenn keine Varianten vorhanden',
  'Use a product stream to select which variants are exported. (default: all)':
    'Wähle mit Hilfe eines Produkt-Streams aus, welche Varianten exportiert werden. (Standard: alle)',
  'Stream (Filter for variants)': 'Stream (Filter für Varianten)',
  None: 'Nicht definiert',
  Header: 'Header',
  'Filter for variants': 'Filter für Varianten',
  Footer: 'Footer',
  'end-of-line string': 'Zeichenfolge für Zeilenende',
  'optional variables (expert mode)': 'optionale Variablen (Expertenmodus)',
  'No separator': 'Kein Trennzeichen',
  Comma: 'Komma',
  Semicolon: 'Semikolon',
  Pipe: 'Pipe',
  Tab: 'Tab',
  'Field wrapper': 'Field wrapper',
  'URL/Download': 'URL/Download',
  'Adjust Structure': 'Struktur anpassen',
  'Download Feed': 'Download Feed',
  'Column name': 'Spaltenname',
  Value: 'Wert',
  'Show column names in first row?': 'Spaltennamen in erster Zeile anzeigen?',
  Duplicate: 'Duplizieren',
  Copy: 'Kopie',
  'Structure for product feed ': 'Struktur für Produkt-Feed ',
  'Edit Product Feed': 'Produkt-Feed bearbeiten',
  'Search configuration': 'Suchkonfiguration',
  'What to search?': 'Was wird durchsucht?',
  'Show search results for the following, additional data:':
    'Suchergebnisse für folgende, zusätzliche Daten anzeigen:',
  'Suggest term': 'Begriff vorschlagen',
  'Select from which field the suggested terms should be loaded when entering a search term:':
    'Wähle, aus welchem Feld die Begriffsvorschläge während der Eingabe eines Suchbegriffs geladen werden sollen:',
  'When this option is active, this menu item will not be used to generate breadcrumb and sidebar navigation.':
    'Wenn diese Option aktiv ist, wird dieser Menüpunkt nicht zur Generierung von Breadcrumb- und Seitenleisten-Navigationen verwendet.',
  'Create Banner': 'Banner erstellen',
  'Edit Banner': 'Banner bearbeiten',
  'Create Search Field': 'Suchfeld erstellen',
  'Edit Search Field': 'Suchfeld bearbeiten',
  'Create Synonym': 'Synonym erstellen',
  'Edit Synonym': 'Synonym bearbeiten',
  'Create Antonym': 'Antonym erstellen',
  'Edit Antonym': 'Antonym bearbeiten',
  Name: 'Name',
  Hierarchy: 'Hierarchie',
  'Adjust filter': 'Filter anpassen',
  'All Categories': 'Alle Kategorien',
  'Not hidden and not archived': 'Nicht versteckt und nicht archiviert',
  'Hidden categories': 'Versteckte Kategorien',
  'Archived categories': 'Archivierte Kategorien',
  'Categories with streams': 'Kategorien mit Streams',
  'Synchronize Categories': 'Kategorien synchronisieren',
  'disabled in search configuration':
    'Datentyp deaktiviert für Suche in Suchkonfiguration (Suche -> Was wird durchsucht)',
  'Enter Secret and Makaira URL in the fields provided.':
    'Gib Secret und Makaira URL in die vorgesehenen Felder ein.',
  'Category Accessories': 'Kategorie Zubehör',
  'Override Aggregations?': 'Filter überschreiben',
  gallery: 'Galerie',
  chooseFromGallery: 'Wähle ein Bild aus der Cloudinary Galerie',
  pending: 'ausstehend',
  'The incremental import via Connect Module is deactivated.':
    'Der inkrementelle Import über das Connect-Modul ist deaktiviert.',
  'The import status could not be retrieved.':
    'Der Status des Imports konnte nicht abgerufen werden.',
  'Please contact the support with the URL of your shop system.':
    'Bitte kontaktiere den Support unter Angabe der URL deines Shop-Systems.',
  dataInspectorDescription: `Der Data Inspector erlaubt es dir, Suchen direkt gegen die Makaira-API auszuführen sowie sich die Produkte aus einem Stream anzeigen zu lassen.
  Nutze die zurückgelieferten Ergebnisse als Vorschau oder als Test, ob bspw. dein Modifier wie gewünscht funktioniert.`,
  dataInspectorModeInfo:
    'Mit dem Modus kannst du wählen, welche Dokumente im Data Inspector angezeigt werden. Die Suchvorschau zeigt dir nur Dokumente, die du auch bei einer Suche im Shop (Autosuggest, Suchergebnisseite) sehen würdest. Die Option "Alles anzeigen" gibt dir zusätzlich noch alle Dokumente aus, die bspw. aufgrund ihres aktiv-Status, dem searchable-Flag oder der abweichenden Shop ID nicht sichtbar wären. Den Grund findest du dann in einer extra Spalte.',
  'Edit Recommendation': 'Recommendation bearbeiten',
  'Create Recommendation': 'Recommendation erstellen',
  Preview: 'Vorschau',
  'Please select recommendation type.':
    'Bitte wähle den Recommendation-Typ aus.',
  'Picture Similarity': 'Bilderähnlichkeit',
  'Text Similarity': 'Textähnlichkeit',
  'Bought together (Association rules)': 'Zusammen gekauft (Association rules)',
  'Behavior history (Cart based)': 'Verhaltensbasiert (Warenkorb)',
  'Behavior history (User based)': 'Verhaltensbasiert (Nutzer)',
  'With filters you can filter down the recommendation products.You can either use concrete values or values based on the product that the recommendation is based on (input product). on multiple input products all field values will be combined and used in query':
    'Mit Filtern können die Recommendations weiter eingeschränkt werden entweder basiert auf Festen Werten oder basierend auf den Werten des Ursprungsprodukts',
  'Populate with Text Similarity': 'Auffüllen mit Textähnlichkeit',
  "Adds more products based on Recommendation Base 'Text Similarity' if not enough results can be retrieved by Recommendation Base":
    "Fügt weitere Produkte auf Basis der Recommendation Base 'Text Similarity' hinzu, wenn nicht genügend Ergebnisse von der Recommendation Base abgerufen werden können",
  PreviewGuide1:
    'Suche nach einem Produkt um Recommendations anzuzeigen. Anschließend kannst Du die Einstellungen der Recommendation ändern und parallel sehen wie sich das Ergebnis verändert.',
  PreviewGuide2: 'Wähle ein Produkt um Recommendations anzuzeigen:',
  PreviewGuide3:
    'Rufe nach dem Erstellen der Recommendation den Bearbeitungsmodus auf, um die Konfiguration zu konfigurieren und die Vorschau anzuzeigen',
  'Output Product Field': 'Produktfeld ausgeben',
  'is true': 'ist wahr',
  'is false': 'ist falsch',
  equals: 'gleich',
  'in list': 'in Liste',
  'equals / in list': 'gleich / in Liste',
  'not equals / not in list': 'ungleich / nicht in Liste',
  like: 'ähnelt',
  'not like': 'ähnelt nicht',
  inList: 'in Liste',
  notInList: 'nicht in der Liste',
  'not in list': 'nicht in der Liste',
  empty: 'ist leer',
  'not empty': 'ist nicht leer',
  'equals number': 'ist gleich',
  greater: 'ist größer als',
  'greater or equal': 'ist größer oder gleich',
  less: 'ist kleiner',
  'less or equal': 'ist kleiner oder gleich',
  between: 'zwischen',
  since: 'seit',
  until: 'bis',
  'within (days)': 'innerhalb (Tage)',
  'between (date)': 'innerhalb (Datum)',
  'use value': 'nutze Wert',
  'Category (category.catid)': 'Kategorie (category.catid)',
  'Static Value': 'Statischer Wert',
  'Input product': 'Eingabeprodukt',
  'The Bundle consists of ...': 'Das Bundle setzt sich zusammen aus ...',
  'Product Group': 'Produktgruppe',
  'has conditions to...': 'hat Bedingungen zu...',
  'Configure Condition': 'Bedingung bearbeiten',
  'Add Group': 'Gruppe hinzufügen',
  'Products for Slot': (slotPosition) => `Produkte für Slot ${slotPosition}`,
  'Choose field for Slot': (number) => `Wähle Feld für Slot ${number}`,
  'Product Selection': 'Produktauswahl',
  Select: 'Wählen',
  'Here you can boost products with specific values to the front of your recommendation list':
    'Hier können Produkte nach vorne geboosted werden, entweder basierend auf festen Werten, auf Werten des Ursprungsprodukts oder basierend auf Werten der Recommendation Produkte.',
  Low: 'Niedrig',
  Medium: 'Mittel',
  High: 'Hoch',
  'Use Makaira Personalization': 'Nutze Makaira Personalisierung',
  'Attention: Has no effect in Preview! Has effect only if you use the Makaira Tracking, enough data is collected and module "Makaira Personalization" is booked':
    "Achtung: Keine Auswirkung in der Preview! Kann nur in Verbindung mit Makaira Tracking genutzt werden, wenn bereits genügend Daten gesammelt wurden und das Modul 'Makaira Personalisierung' gebucht wurde.",
  'Use Ranking-Mix': 'Nutze Ranking-Mix',
  Randomize: 'Zufallsfaktor',
  'Only a small rescoring will take place so that most likely no irrelevant products are shown on the beginning of the list':
    'Hierbei wird nur ein geringfügiger Zufallsfaktor addiert, damit kaum irrelevanten Produkte an den Beginn der Liste sortiert werden',
  'Caution: A/B-Experiment is running!': 'Achtung: A/B-Experiment läuft!',
  'Show only products where all of the following conditions apply:':
    'Zeige nur Produkte auf die alle der folgenden Bedingungen zutreffen:',
  'Recommendation Basic': 'Recommendation Basis',
  Comparison: 'Vergleich',
  ComparisonWith: 'vergleiche mit',
  bannerDescription:
    'Platziere Produkte, Hersteller und Kategorien manuell. Damit bekommst du volle Kontrolle für die Top-Positionen deiner Artikellisten. Dadurch lassen sich ebenso bezahlte Platzierungen, wie beispielsweise bei Werbekostenzuschüssen üblich, realisieren.',
  contactInformation:
    'Wenn Du an dieser Funktion interessiert bist, kontaktiere uns einfach unter support@makaira.io',
  streamsDescription:
    'Mit den Product-Streams in der Pro-Version kommt Dynamik ins Spiel. Fülle Kategorien und Landingpages automatisch mit den aktuell passenden Produkten. Booste Artikelgruppen um den Abverkauf zu steigern und wähle Produkte für die Exporte.',
  productfeedsDescription:
    'Schnelle und dynamische Datenexporte geben dir die volle Kontrolle über deine Daten. Ob Google-Feed, Excel-Export für das Produktmanagement oder eine schnelle Überprüfung - mit der Feed-Engine hälst du die Zügel in der Hand.',
  machine_learningDescription:
    'Dank Operational Intelligence können wir deine Suchergebnisse und Artikellisten performant personalisieren. Was kryptisch klingt lässt sich am Beispiel einfach zeigen. Sprich uns gerne für eine Demo an und lass uns gemeinsam das Maximum aus deinen Daten holen.',
  recommendationsDescription:
    'Ob Topseller, Abverkauf des Lagers, Kundenliebling oder Upselling über ähnliche Produkte - leite deine Kunden gezielt auf passende Artikel und steigere deinen Umsatz.',
  mecDescription:
    'Headless E-Commerce und volle Kontrolle über das Online Marketing: Mit den Modulen "Page Builder" und "Menü Editor" bekommst du über alle Content Elemente die volle Kontrolle.\nZeitgesteuert und personalisiert lieferst du die besten Inhalte für deine Kunden optimal aus. Das ist die doppelte Performance von Makaira.',
  ab_testingDescription:
    'Finde die beste Makaira-Konfiguration für deinen Shop durch datenbasierte A/B-Tests oder teste verschiedene Frontend-Implementierungen gegeneinander. Wenn Du das Makaira Tracking bereits nutzt, kannst du sofort loslegen!',
  bundlesDescription:
    'Erstelle ein Smart Bundle, damit deine Kunden zusammengehörige Produkte nacheinander auswählen und zusammen kaufen können. So erzeugst du mehr Umsatz durch Cross- und Upselling.',
  page_editorDescription:
    'Headless E-Commerce und volle Kontrolle über das Online Marketing: Mit den Modulen "Page Builder" und "Menü Editor" bekommst du über alle Content Elemente die volle Kontrolle.\nZeitgesteuert und personalisiert lieferst du die besten Inhalte für deine Kunden optimal aus. Das ist die doppelte Performance von Makaira.',
  'Adjust filter of': 'Filter anpassen von',
  'Switch to old UI': 'Zurück zu alter UI',
  'Config options': 'Konfigurationsoptionen',
  Copied: 'Kopiert',
  Character: 'Zeichen',
  'Please contact': 'Bitte kontaktiere uns unter',
  'to get your Tracking set up.': 'um deine Tracking ID zu erhalten.',
  "We are already working on the new version of the page editor. It will get a complete overhaul so that it's even easier for you to create and maintain your content pages. In the meantime please use the page editor in the old UI":
    'Wir arbeiten bereits an einer neuen Version des Page Editors. Wir werden ihn komplett überarbeiten, sodass es noch einfacher denn je ist deine Content Seiten zu bearbeiten. Nutze bis dahin bitte den Page Editor in der alten UI Version.',
  "Don't show this information again, always switch to old UI for page editor.":
    'Diese Information nicht mehr anzeigen, immer in die alte UI springen für den Page Editor.',
  'Open Page Editor in old UI': 'Page Editor in der alten UI öffnen',
  "You don't have access to any instances on this Makaira Environment.":
    'Du hast keinen Zugriff auf Instanzen in dieser Makaira-Umgebung.',
  'There are some problems with loading this site.':
    'Beim Laden dieser Seite sind Probleme aufgetreten.',
  'Go to dashboard': 'Zurück zum Dashboard',
  wrong_instance_redirect: (oldInstance, newInstance) =>
    `Du hast keinen Zugriff auf die Instanz "${oldInstance}". Du wurdest weitergeleitet auf Instanz "${newInstance}".`,
  "You don't have access to this Makaira installation.":
    'Du hast keinen Zugriff auf diese Makaira Installation.',
  'This Makaira Installation does not exist - please check your URL for typos.':
    'Diese Makaira Installation existiert nicht - bitte prüfe Deine URL auf Schreibfehler.',
  'Makaira could not be booted because of an error, please contact our support at':
    'Makaira konnte leider aufgrund eines Fehlers nicht gestartet werden, bitte wende dich an den Support via',
  'Click to sort ascending': 'Klicke, um aufsteigend zu sortieren',
  'Click to sort descending': 'Klicke, um absteigend zu sortieren',
  'Search for Smart Bundles': 'Suche nach Smart Bundles',
  'Smart Bundles have been deleted.': 'Smart Bundles wurden gelöscht.',
  'Smart Bundle has been deleted.': 'Smart Bundle wurde gelöscht.',
  'Smart Bundle could not be deleted.':
    'Smart Bundle konnte nicht gelöscht werden.',
  'Something went wrong loading the smart bundles.':
    'Beim Laden der Smart Bundles ist ein Fehler aufgetreten.',
  'Configuration done': 'Konfiguration fertig',
  Metadata: 'Metadata',
  Slots: 'Slots',
  Configuration: 'Konfiguration',
  Revision: 'Revisionen',
  Apply: 'Anwenden',
  Search: 'Suche',
  'Enter title...': 'Titel angeben...',
  'Enter text...': 'Text angeben...',
  Image: 'Bild',
  'New Bundle…': 'Neues Bundle…',
  'Internal title': 'Interner Bezeichner',
  German: 'Deutsch',
  English: 'Englisch',
  'UI Language': 'UI Sprache',
  More: 'Mehr',
  'There are unsaved changes in Makaira. Are you sure you want to leave this page?':
    'Es gibt noch noch ungespeicherte Änderungen im Makaira. Möchtest du die Seite wirklich verlassen?',
  delete_modal_title: (count) =>
    count === 1
      ? `Möchtest du wirklich dieses Element löschen?`
      : `Möchtest du wirklich ${count} Elemente löschen?`,
  Execute: 'Ausführen',
  Documentation: 'Dokumentation',
  'API Documentation': 'API Dokumentation',
  Instances: 'Instanzen',
  Published: 'Veröffentlicht',
  'Title is mandatory': 'Titel wird benötigt',
  'Placeholder Image': 'Platzhalterbild',
  'Display Settings': 'Darstellung',
  'Abort and back to listing': 'Abbrechen und zurück zur Liste',
  Continue: 'Weiter',
  'Full Import required': 'Vollimport benötigt',
  'The changes of your bundles require a full import of your data, please trigger the full import after you added all changes to your bundles.':
    'Die Änderungen an deinem Bundle benötigen einen Vollimport deiner Daten, bitte starte den Vollimport, sobald du alle Änderungen an deinen Bundles abgeschlossen hast.',
  "That's how your bundle is displayed in the frontend ...":
    'So sieht dein Bundle im Frontend aus ...',
  'Add Condition': 'Bedingung hinzufügen',
  Settings: 'Einstellungen',
  Restore: 'Wiederherstellen',
  'You have unsaved changes, do you really want to jump to the old revision? Your current changes will be lost.':
    'Es gibt noch ungespeicherte Änderungen, möchtest du wirklich zur alten Revision springen? Deine Änderungen gehen dadurch verloren.',
  revision_restored: (time) => `Revision vom ${time} wiederhergestellt.`,
  'It looks like not everything runs smoothly currently, but thats not the end of the world. We registered the error. Unfortunately we could not save your changes.':
    'Wenn etwas schief geht ist das meistens nicht das Ende der Welt. Wir haben den Fehler registriert. Leider funktioniert das Speichern gerade nicht.',
  'The sky comes tumbling down …': 'Was ist da denn los …',
  'URL should start with a slash':
    'Die URL muss mit einem Schrägstrich beginnen',
  'This URL is not available.': 'Diese URL ist nicht verfügbar.',
  'Internal title has to be filled.': 'Interner Bezeichner wird benötigt.',
  'Internal title can not be longer than 255 characters.':
    'Interner Bezeichner kann maximal 255 Zeichen lang sein.',
  'Something went wrong loading the revisions of the smart bundle.':
    'Beim Laden der Revisionen des Smart Bundles ist ein Fehler aufgetreten.',
  'Something went wrong restoring the revision of the smart bundle.':
    'Beim Wiederherstellen des Smart Bundles ist ein Fehler aufgetreten.',
  'You can edit your bundle navigation’s content in this section.':
    'In diesem Abschnitt kannst du den Inhalt deiner Bundle-Navigation bearbeiten',
  Group: 'Gruppe',
  'Settings for Group': 'Einstellungen für Gruppe',
  'Create Smart Bundle': 'Smart Bundle erstellen',
  'Create a Smart Bundle to let your customers choose multiple products in a row\nthat belong together or to create more revenue through cross- and upselling.':
    'Erstelle ein Smart Bundle, damit deine Kunden zusammengehörige Produkte nacheinander auswählen und zusammen kaufen können.\nSo erzeugst du mehr Umsatz durch Cross- und Upselling.',
  'Here you will find older versions of your bundle. Each save creates a new revision. We keep 20 revisions at max.':
    'Hier wirst du ältere Versionen deines Bundles finden. Jedes Speichern erzeugt eine neue Revision. Wir behalten maximal 20 Revisionen.',
  Identifier: 'Identifier',
  Productselection: 'Produktauswahl',
  condition_modal_header: (slot1, slot2) =>
    `Bedingung zwischen Slot ${slot1} und Slot ${slot2} bearbeiten`,
  'Field for Slot': (number) => `Feld für Slot ${number}`,
  'with Slot': 'mit Slot',
  'Add Slot': 'Slot hinzufügen',
  'Settings for Slot': 'Einstellungen für Slot',
  'Please select a field.': 'Bitte wähle ein Feld.',
  'Smart Bundles could not be deleted.':
    'Smart Bundles konnten nicht gelöscht werden.',
  'Try again': 'Erneut versuchen',
  'Choose the filter criteria for the products of Slot': (slotPosition) =>
    `Wähle die Filterkriterien für die Produkte für Slot ${slotPosition} aus.`,
  'and will be displayed like …': 'und wird wie folgt dargestellt …',
  'Connect Slot X with …': (slotPosition) =>
    `Verbinde Slot ${slotPosition} mit …`,
  'Will be shown in Makaira admin overviews':
    'Wird in Makaira Admin Übersichten angezeigt',
  'Can be used in Frontend for technical reasons':
    'Kann im Frontend für technische Zwecke genutzt werden',
  importer: 'importer',
  'Index Snapshots': 'Index Snapshots',
  'load more entries': 'Mehr Einträge laden',
  'Results per page': 'Ergebnisse pro Seite',
  'Index Overview': 'Index Übersicht',
  'once the rebuilding process is finished this index will be auto-activated as the live index':
    'Sobald der Neuaufbau abgeschlossen ist, wird dieser Index automatisch als Live-Index aktiviert',
  'Use as live index': 'Als Live-Index verwenden',
  'Shop languages could not be retrieved.':
    'Shop-Sprachen konnten nicht abgerufen werden.',
  'Index & Importer': 'Index & Importer',
  Document: 'Dokumente',
  'Index for': (lang) => `Index für ${lang}`,
  'Build new index for all languages': 'Index für alle Sprachen neu aufbauen',
  all: 'alle',
  Languages: 'Sprachen',
  'Multiple e-mail addresses separated by comma':
    'Mehrere E-Mail-Adressen kommasepariert',
  'Maximum batch size for import': 'Maximale Batch-Größe für den Import',
  'Stop rebuilding of index': 'Neuaufbau stoppen',
  Rebuilding: 'Neuaufbau läuft',
  'Importer Configuration & Schedules': 'Importer Konfiguration & Schedules',
  'Importer Configuration': 'Importer Konfiguration',
  'Source Type': 'Quelltyp',
  'No data': 'Keine Daten',
  'Next run': 'Nächste Ausführung',
  Overview: 'Übersicht',
  'All Indices': 'Alle Indizes',
  Size: 'Größe',
  Created: 'Erstellt',
  'Something went wrong loading the importer status.':
    'Beim Laden des Importer Status ist ein Fehler aufgetreten.',
  'Something went wrong loading the importer listing.':
    'Beim Laden der Importer ist ein Fehler aufgetreten.',
  'Something went wrong saving the importer.':
    'Beim Speichern des Importers ist ein Fehler aufgetreten.',
  'Something went wrong building the new index.':
    'Beim Bauen des Index ist ein Fehler aufgetreten.',
  Activated: 'Aktiviert',
  'Other Source': 'Andere Quelle',
  Source: 'Quelle',
  'Notifications will be sent to': 'Benachrichtigungen werden gesendet an',
  'Source-URL has to be filled.': 'Source-URL wird benötigt.',
  Basics: 'Grundlegendes',
  'Enter title…': 'Titel eingeben…',
  'Enter URL…': 'URL eingeben…',
  'Basic Auth User': 'Basic Auth Nutzername',
  'Enter User…': 'Nutzername eingeben…',
  'Leave empty if no Basic Auth is needed.':
    'Leer lassen, wenn kein Basic Auth benötigt wird.',
  'NDJSON File': 'NDJSON Datei',
  'Limit languages': 'Sprachen auswählen',
  'Import all available languages': 'Alle verfügbaren Sprachen importieren',
  'Import only the following languages': 'Nur folgende Sprachen importieren',
  'Fetching shop languages…': 'Shop-Sprachen werden abgerufen',
  'You can setup a automated scheduled rebuild for your index if needed. For example for constantly changing NDJSON files \n or it your ERP system is operating directly on the database of your shop system where our Connect plugins cannot recognize changes.':
    'Du kannst bei Bedarf einen automatischen, geplanten Neuaufbau für deinen Index einrichten. Zum Beispiel für sich regelmäßig ändernde NDJSON-Dateien \n oder wenn dein ERP-System direkt auf die Datenbank des Shopsystems zugreift und damit die Connect-Plugins Änderungen nicht erkennen können.',
  'Schedule #': 'Planung #',
  minutes: 'Minuten',
  Every: 'Jede',
  Hourly: 'Stündlich',
  Daily: 'Täglich',
  'minutes past the hour': 'Minuten nach der vollen Stunde',
  'Add rebuild schedule': 'Planung hinzufügen',
  'We will only send out notifications if one of the importers cannot recover for more than 10 minutes \n or if the maximum execution time for an import is reached.':
    'Wir versenden nur dann Meldungen, wenn einer der Importer mehr als 10 Minuten lang nicht mehr funktioniert \n oder wenn die maximale Ausführungszeit für einen Neuaufbau erreicht ist.',
  'Notifications for errors': 'Fehlerbenachrichtigungen',
  Notify: 'Benachrichtigen',
  'Send notifications to': 'Benachrichtigungen senden an',
  'Enter email…': 'E-Mail eingeben…',
  'Maximum runtime for rebuilds (seconds)':
    'Maximale Laufzeit für Neuaufbau (Sekunden)',
  Date: 'Datum',
  Time: 'Uhrzeit',
  'Between…': 'Zwischen…',
  'Select Timestamp': 'Zeitpunkt auswählen',
  '…and': '…und',
  'Select Type': 'Typ auswählen',
  'All importers': 'Alle Importers',
  'Select Importer': 'Importer auswählen',
  'Build new index': 'Index neu aufbauen',
  'Rebuild Index Schedule': 'Geplanter Neuaufbau',
  'Enter password…': 'Passwort eingeben…',
  'All log entries': 'Alle Log-Einträge',
  Message: 'Nachricht',
  archived: 'archiviert',
  'Index has been activated!': 'Index wurde aktiviert!',
  'Not running': 'Läuft nicht',
  'Delete Schedule': 'Planung löschen',
  running: 'Laufend',
  ended: 'Beendet',
  activating: 'Aktiviere Index',
  Terminating: 'Wird gestoppt',
  Pending: 'Wird gestartet',
  'Manual Rebuild': 'Manueller Neuaufbau',
  'Manual Rebuild is running currently': 'Manueller Neuaufbau läuft aktuell',
  'one-time': 'manuell',
  scheduled: 'geplant',
  continuously: 'kontinuierlich',
  All: 'Alle',
  indexListDescription:
    'Ein "Index" hält alle Shop und Makaira-Daten vor für die schnelle Auslieferung an dein Frontend.\nAktuell existiert noch kein Index, bitte konfiguriere deinen Importer um deinen ersten Index automatisch zu erstellen und die Daten im Makaira aktuell zu halten.',
  'Configure Importer': 'Importer konfigurieren',
  importerDescription:
    'Kein Importer konfiguriert.\nDer Importer lädt automatisch Daten von deinem Shopsystem um Produkte, Kategorien, Hersteller im Makaira bereitzustellen.',
  'Create Importer Configuration': 'Importer-Konfiguration erstellen',
  Notification: 'Notification',
  App: 'App',
  'Notification and details': 'Benachrichtigung und Details',
  Unread: 'Ungelesen',
  'No unread messages.': 'Keine ungelesenen Benachrichtigungen.',
  'All notifications': 'Alle Benachrichtigungen',
  unread: 'ungelesen',
  Notifications: 'Benachrichtigungen',
  'Mark all as read': 'Alle als gelesen markieren',
  warning: 'Warnung',
  error: 'Fehler',
  'Took (in seconds)': 'Dauer (in Sekunden)',
  'No results': 'Keine Ergebnisse',
  'Component Editor': 'Komponenteneditor',
  'Drag and drop an image to upload it.':
    'Drag and drop an image to upload it.',
  'Then start adding hotspots ...': 'Then start adding hotspots ...',
  'Copy to the input below.': 'Ins Feld unten kopieren.',
  'No selection': 'Keine Auswahl getroffen',
  Selection: 'Auswahl',
  Selected: 'Ausgewählte',
  product_plural: 'Produkte',
  'Discard all': 'Alle entfernen',
  'Select all': 'Alle auswählen',
  'Deselect all': 'Alle abwählen',
  'What would you like to put on the list?':
    'Was möchtest du in die Liste legen?',
  link_plural: 'Links',
  searchredirect_plural: 'Such-Redirects',
  page_plural: 'Landingpages',
  category_plural: 'Kategorien',
  manufacturer_plural: 'Hersteller',
  'Product stream': 'Produkt-Stream',
  'No product stream selected': 'Kein Produktstream ausgewählt',
  'Sort by': 'Sortierfeld',
  'Sort order': 'Sortierreihenfolge',
  'Max. Results': 'Max. Ergebnisanzahl',
  Descending: 'Absteigend',
  Ascending: 'Aufsteigend',
  'Upload an image and start adding hotspots by clicking on the picture.':
    'Lade ein Bild hoch und beginne mit dem Hinzufügen von Hotspots, indem du auf das Bild klickst.',
  'Select…': 'Auswählen…',
  'time-based or persona based visibility can be changed':
    'Die zeitbasierte oder personenbasierte Sichtbarkeit kann geändert werden',
  'Visible for': 'Sichtbar für',
  Playout: 'Ausspielung',
  'Configuring User Groups': 'Benutzergruppen konfigurieren',
  'Time-controlled playout': 'Zeitgesteuert ausspielen',
  'Here you can set up the URL for the Preview that will be used by default by the page editor preview':
    'Here you can set up the URL for the Preview that will be used by default by the page editor preview',
  'CAUTION! This setting is global, every author will be affected by changes of the URL. Do you really want to update the setting?':
    'ACHTUNG! Diese Einstellung ist global, jeder Autor wird von dieser Änderung betroffen sein. Einstellung wirklich ändern?',
  'Product Grid': 'Produkliste',
  Author: 'Autor',
  'no added content yet': 'noch kein Inhalt hinzugefügt',
  'Add Landing page': 'Landingpage erstellen',
  'Add Snippet': 'Snippet erstellen',
  Activate: 'Aktivieren',
  Deactivate: 'Deaktivieren',
  'Add a component': 'Komponente hinzufügen',
  Content: 'Inhalt',
  Searchable: 'Suchbar',
  'Here you can set up the URL that will be used by default by the Page Editor Live Preview.':
    'Hier kann die URL eingestellt werden, die standardmäßig für die Live Vorschau genutzt wird.',
  Revisions: 'Revisionen',
  'You can enter a private url for development':
    'Du kannst eine private Vorschau-URL setzen',
  "Your storefront does not support the preview feature yet. But don't worry you can fix that!":
    'Deine Storefront unterstützt die Preview-Funktion bisher nicht. Aber keine Sorge, das lässt sich beheben!',
  'No preview available': 'Keine Vorschau verfügbar',
  'Open documentation': 'Dokumentation öffnen',
  'No content yet': 'Kein Inhalt bisher',
  'Here you will find older versions of your page.\nEach save creates a new revision. We keep 20 revisions at max.':
    'Hier wirst du ältere Versionen deiner Seite finden.\nJedes Speichern erzeugt eine neue Revision. Wir behalten maximal 20 Revisionen.',
  'All types': 'Alle Typen',
  'Please enter a correct url.': 'Bitte gib eine korrekte URL an.',
  'Please enter a title.': 'Bitte gib einen Titel an.',
  'This URL is already in use.': 'Diese URL wird bereits benutzt.',
  'Live Preview': 'Live Vorschau',
  'Essential Snippet': 'Essential Snippet',
  'Please enter an url.': 'Bitte gib eine URL an.',
  'Content Editor': 'Content Editor',
  Snippet: 'Snippet',
  'no title yet': 'noch kein Titel',
  'Create your first landingpage to tell your customers about your business!':
    'Lege deine erste Landingpage an um deinen Kunden von deinem Geschäft zu erzählen!',
  'Landing pages': 'Landingpages',
  'Landing page': 'Landingpage',
  Landingpage: 'Landingpage',
  Categories: 'Kategorien',
  'Please enter a correct Snippet-ID.':
    'Bitte gib eine korrekte Snippet-ID an.',
  'Copy from language': 'aus Sprache kopieren',
  'Page Editor': 'Page Editor',
  'Filter by type': 'Nach Typ filtern',
  'Nothing found - just create a new landing page or reset your search & filters above.':
    'Nichts gefunden - lege eine neue Landingpage an oder setze deine Filter oben zurück.',
  'Nothing found - just create a new Advertising Space or reset your search & filters above.':
    'Nichts gefunden - lege eine neue Werbeplätze an oder setze deine Filter oben zurück.',
  Scheduled: 'Zeitgesteuert',
  to: 'bis',
  'Open Preview in new window': 'Preview in neuem Fenster öffnen',
  'Open published version': 'Veröffentlichte Version öffnen',
  'Abort & back to list': 'Abbrechen und zurück zur Liste',
  'Save & back to list': 'Speichern und zurück zur Liste',
  'Save & continue': 'Zwischenspeichern',
  Done: 'Fertig',
  'Delete content': 'Content löschen',
  'Product details': 'Produktdetails',
  'Setup Information': 'Setup Informationen',
  'Our Makaira Connect modules are open source and everyone is invited to contact us at GitHub ':
    'Unsere Makaira Connect Module sind Open Source und jeder ist eingeladen, mit uns in Kontakt zu treten unter ',
  'We are very happy to receive feedback in the form of issues or pull requests.':
    'Über Feedback in Form von Issues oder Pull Requests freuen wir uns sehr.',
  'You can find installation instructions for Shopify, OXID, Shopware, Plentymarkets in the ':
    'Eine Anleitung zur Installation für Shopify, OXID, Shopware, Plentymarkets findest Du in der ',
  '1. Installation of our Makaira Connect Plugin in your shop system':
    'Installation des Makaira Connect Plugins in deinem Shopsystem',
  '2. Configuration of the plugin': '2. Konfiguration des Plugins',
  'Makaira documentation': 'Makaira Dokumentation',
  'Something went wrong fetching the setup information.':
    'Das Laden der Setup-Informationen ist fehlgeschlagen.',
  hide: 'ausblenden',
  'show preview': 'Preview anzeigen',
  'Hide Preview': 'Preview ausblenden',
  'Show Preview again': 'Preview erneut anzeigen',
  Paste: 'Einfügen',
  'You are about to paste components from a different language. Please confirm the mapping if you want to proceed:':
    'Du fügst gerade Komponenten einer anderen Sprache ein. Bitte bestätige die Zuordnung, um fortzufahren:',
  'Copy all components': 'Alle Komponenten kopieren',
  'components from page': 'Komponenten von Seite',
  copy_verb: 'Kopieren',
  'Original Language': 'Ausgangssprache',
  'Destination Language': 'Zielsprache',
  mapping_description:
    'Du fügst gerade Komponenten auf einer Seite ein, die über mehr Sprachen verfügt. Für alle Sprachen muss eine Übersetzung auf der Seite vorliegen. Bitte wähle eine Vorlage um mit der Übersetzung zu beginnen. Wähle über die Auswahl unten die Sprache die du als Vorlage nutzen möchtest um dir bei der Übersetzung zu helfen, somit wird der Inhalt des Originals in die Sprache deiner Wahl kopiert. Wähle “Nicht zuordnen” um die Sprache leer zu lassen.',
  "Don't map": 'Nicht zuordnen',
  'Warning: Component structure changed':
    'Warnung: Komponenten-Struktur wurde geändert',
  'The components glowing blue in the list were adapted. It seems like the component structure has been changed after the last edit of this page. We just updated the data structure for you. Please check the content and save it afterwards.':
    'Die blau hervorgehobenen Komponenten in der Liste wurden angepasst. Es sieht so aus als hätte sich die Komponenten-Struktur geändert seit der letzten Änderung dieser Seite. Wir haben soeben die Struktur auf den letzten Stand gebracht. Bitte prüfe den Inhalt und speichere ihn anschließend.',
  'Structure updated': 'Komponenten-Struktur geändert',
  Warning: 'Warnung',
  'Language Mapping': 'Sprachzuordnung',
  'Media will be removed': 'Medien werden entfernt',
  'Your media content of the components will be removed because of a missmatch of your media configuration. Please click Paste if you are aware of that.':
    'Die Medien der Komponenten werden entfernt, da die Medien-Konfiguration zwischen nicht mit dem Original zusammenpasst. Klicke auf Einfügen um fortzufahren.',
  Waiting: 'Wartend',
  'Component not available': 'Komponente nicht verfügbar',
  'You are viewing': 'Du betrachtest',
  'Your Makaira Personalization works, but could perform better! Please check the following points:':
    'Deine Makaira Personalisierung funktioniert, aber könnte bessere Ergebnisse liefern! Bitte prüfe die folgenden Punkte:',
  'Tracking of product detail pages': 'Tracking von Produktdetailseiten',
  'Great, this seems to work. Well done!': 'Großartig, das funktioniert!',
  'Tracking of abandoned carts': 'Tracking von abgebrochenen Warenkörben',
  'Tracking of orders': 'Tracking von Bestellungen',
  'Tracked EAN codes have to match your products':
    'Getrackte EAN-Codes müssen zu deinen Produkten passen',
  '14 days of successful tracking': '14 Tage erfolgreiches Tracking',
  'Grouping fields fulfill requirements':
    'Gruppierungsfelder erfüllen Anforderungen',
  'Machine Learning was calculated successfully':
    'Maschinelles Lernen wurde erfolgreich berechnet',
  haveDetailPageCalls_error: (
    <>
      Oh, diese Prüfung ist fehlgeschlagen. In{' '}
      <a href="https://docs.makaira.io/docs/tracking">
        unserer Tracking-Dokumentation
      </a>{' '}
      findest du alle Infos, um das Tracking richtig einzurichten.
      <br />
      Das Tracking für Detailseiten ist nicht zwingend erforderlich für die
      Personalisierung, kann aber für Recommendations und den Rankingmix genutzt
      werden.
    </>
  ),
  haveAbandonedCartCalls_error: (
    <>
      Oh, diese Prüfung ist fehlgeschlagen. In{' '}
      <a href="https://docs.makaira.io/docs/tracking">
        unserer Tracking-Dokumentation
      </a>{' '}
      findest du alle Infos, um das Tracking richtig einzurichten.
      <br />
      Das Tracking für abgebrochene Warenkörbe ist nicht zwingend erforderlich
      für die Personalisierung, kann aber für Recommendations und den Rankingmix
      genutzt werden.
    </>
  ),
  haveOrderCompletedCalls_error: (
    <>
      Oh, diese Prüfung ist fehlgeschlagen. In{' '}
      <a href="https://docs.makaira.io/docs/tracking">
        unserer Tracking-Dokumentation
      </a>{' '}
      findest du alle Infos, um das Tracking richtig einzurichten.
    </>
  ),
  checkEansOnMatomoExistsInMakaira_error: (
    <>
      Oh, diese Prüfung ist fehlgeschlagen. In{' '}
      <a href="https://docs.makaira.io/docs/checking-the-tracking-function#usage-of-sku--ean-as-unique-match-between-tracking-and-makaira-data">
        unserer Dokumentation zum SKU/EAN Matching
      </a>{' '}
      findest du alle Infos, um das Tracking richtig einzurichten.
      <br />
      Einige EANs, die via Tracking an uns gesendet wurden, passen niciht deinen
      importierten Produktdaten zusammen.
    </>
  ),
  'We still have to wait some days until your tracking data is sufficient for our algorithms.':
    'Wir müssen noch einige Tage warten, bis deine Tracking-Daten für unsere Algorithmen ausreichend sind.',
  checkTermAggregationOnGroupingFields_error: (
    <>
      Oh, diese Prüfung ist fehlgeschlagen. Bitte lese die{' '}
      <a href="https://docs.makaira.io/docs/personalization#grouping-fields-in-personalization">
        Dokumentation über Gruppierungs-Felder in der Personalisierung
      </a>
      .
    </>
  ),
  'Our algorithms still have to prepare your data.':
    'Unsere Algorithmen müssen deine Daten noch aufbereiten.',
  'Detailed Reason': 'Detailierter Grund',
  Personalization: 'Personalisierung',
  'In order for the Personalization feature to work make sure that the following checks are fulfilled':
    'Damit die Personalisierung funktioniert, müssen die folgenden Bedingungen erfüllt sein',
  'Makaira Personalization is not available on stage systems.\nPlease book a live instance to see the setup procedure on this screen.':
    'Makaira Personalisierung ist auf Stage-Systemen nicht verfügbar.\nBitte buchen Sie eine Live-Instanz, um das Setup-Verfahren auf diesem Bildschirm zu sehen.',
  'Run checks again': 'Überprüfung wiederholen',
  'Alle Benutzer': 'Alle Regionen',
  'All Personas': 'Alle Personas',
  'Rest der Welt': 'Andere Regionen',
  'New Landing page...': 'Neue Landingpage...',
  'New Snippet...': 'Neues Snippet...',
  'Add menu entry': 'Eintrag hinzufügen',
  Menu: 'Menü',
  'Menu Editor': 'Menü Editor',
  'Bulk Edit Mode': 'Massenbearbeitung',
  'Override manually': 'Manuell überschreiben',
  'Type a search word above to get your first results shown.':
    'Tippe ein Suchwort oben ein um die ersten Ergebnisse zu sehen.',
  'Prefill menu entry': 'Menüeintrag automatisch füllen',
  'Prefilled menu entry': 'Automatischer Menüeintrag',
  'Show title and url in': 'Zeige Titel und URL auf',
  your: 'deine',
  'Settings for': 'Einstellungen für',
  'CSS-Classes for this menu entry': 'CSS Klassen für diesen Menüeintrag',
  'This classes are inserted as an attribute of the <a>-tag. Multiple classes separated with space':
    'Diese Klassen werden als Attribute des <a>-Tag hinterlegt. Mehrere Klassen bitte mit Leerzeichen trennen!',
  Visibility: 'Sichtbarkeit',
  "Only show in menu and don't use for breadcrumbs":
    'Eintrag wir nur im Hauptmenü dargestellt, keine Breadcrumb',
  'Begin URL with "/" or https:// for external links':
    'URL muss mit "/" oder https:// für externe Links beginnen',
  language_at: 'Deutsch (Österreich)',
  language_ch: 'Deutsch (Schweiz)',
  language_cz: 'Tschechisch',
  language_dk: 'Dänisch',
  language_se: 'Schwedisch',
  best_variant: (effect = '', rate) =>
    `Beste Variante ist ${
      effect.startsWith('-') ? 'ohne' : 'mit'
    } Makaira Personalisierung mit ${effect.replace(
      '-',
      ''
    )}% mehr Umsatz mit einer Signifikanz von ${
      rate !== 'NOT_ENOUGH_VISITS' ? rate + '%' : '< 50%'
    }`,
  trial_days_left: (days) =>
    days === 0 ? (
      <>
        <strong>Letzter Tag</strong> …
      </>
    ) : (
      <>
        Du hast <strong>{days} Tage</strong> übrig…
      </>
    ),
  ml_trial_description_1: (
    <>
      Um unsere Personalisierung in Aktion zu sehen, bieten wir eine{' '}
      <b>kostenlose 30-Tage-Testphase</b> an. Viele unsere Kunden haben damit
      einen <b>Umsatzanstieg von 3% bis 15%</b> erlebt.
    </>
  ),
  'The Machine Learning feature is properly configured. See it in action below…':
    'Die Makaira Personalisierung ist korrekt konfiguriert, schaue dir das Ergebnis unten an…',
  "What's next?": 'Nächster Schritt?',
  'Try Machine Learning for free for 30 days':
    'Teste die Makaira Personalisierung kostenfrei für 60 Tage',
  'The best way to see the effects of the machine learning is to run an A/B test with versus without the machine learning enabled.':
    'Der einfachste Weg um den Effekt der Personalisierung zu prüfen, ist ein A/B-Test mit Personalisierung vs. ohne Personalisierung.',
  'We will set up a test for you so the effects can be measured and seen. We recommend splitting the traffic in half for the experiment.':
    'Wir setzen einen A/B-Test für dich auf, sodass du den Effekt messen und sehen kannst, bevor du das Feature buchst. Wir empfehlen einen 50%/50% Traffic Split.',
  'Testing is easy and smart': 'A/B-Tests sind einfach und schnell',
  'Instant status insights': 'Schnelle Erkenntnisse',
  'Results within a few days': 'Erste Ergebnisse nach wenigen Tagen',
  'No automatic subscription': 'Kein automatisches Abo',
  'Cancel at any time': 'Jederzeit stoppbar',
  'Experience the Makaira Personalization':
    'Erlebe den Effekt der Makaira Personalisierung',
  'Displaying results for…': 'Vorschau der Produkte für…',
  'with Machine Learning': 'mit Makaira Personalisierung',
  User: 'Nutzer',
  'Page type': 'Seitenart',
  'Search results': 'Suchergebnisse',
  'Product / EAN': 'Produkt / EAN',
  "Okay, let's go!": "Okay, auf geht's!",
  'How much traffic should be sent to the Makaira Personalization?':
    'Wie viel Traffic soll auf die Makaira Personalisierung geleitet werden?',
  recommended: 'empfohlen',
  'Start A/B test and trial': 'A/B-Test und Testphase starten',
  'Current Trial & A/B test results': 'Aktuelle Test- und A/B-Testergebnisse',
  'Upper limit ': 'Obergrenze ',
  'Lower limit ': 'Untergrenze ',
  'Revenue per visit in €': 'Umsatz pro Besuch in €',
  "… to test the machine learning feature and improve your shop's performance.":
    'um die Makaira Personalisierung zu testen und die Leistung deines Shops zu verbessern.',
  'After that we will end the A/B test and return your shop to the original state. From that point on please get in touch with us to reactivate the feature.':
    'Danach beenden wir den A/B-Test und versetzen deinen Shop in den ursprünglichen Zustand zurück. Bitte setze dich ab diesem Zeitpunkt mit uns in Verbindung, um die Funktion wieder zu aktivieren.',
  'See pricing and options': 'Preise und Optionen ansehen',
  'Your trial period is over …': 'Deine Testphase ist vorbei …',
  '… it seems your shop did well with the Machine Learning switched on.':
    '… es scheint, dass dein Shop mit der eingeschalteten Makaira Personalisierung gut zurechtkommt.',
  'It would be a shame to waste the opportunity to make the most of your shop. We recommend booking the feature now to benefit in future.':
    'Es wäre schade, die Gelegenheit zu verpassen, das Beste aus deinem Shop herauszuholen. Wir empfehlen, das Feature jetzt zu buchen, um in Zukunft davon zu profitieren.',
  'Book personalization': 'Personalisierung buchen',
  'Trial period over': 'Testphase ist vorbei',
  'Audit Log for': 'Audit Log für',
  'Logs Count': 'Anzahl Einträge',
  'Object ID': 'Objekt ID',
  'Type of change': 'Typ der Änderung',
  'By account': 'via Account',
  'Date of edit': 'Datum der Änderung',
  audit_logDescription:
    'Mit Audit logs kannst du jede Änderung im Makaira nachvollziehen. Wer hat wann welche Änderung vorgenommen?',
  'View Changes': 'Differenz',
  'Show complete value': 'Kompletten Wert anzeigen',
  '(No Preview for Objects and Arrays)':
    '(Keine Vorschau für Objekte und Arrays)',
  day_left: (days) => `noch ${days === 1 ? 'einen Tag' : days + ' Tage'}`,
  'Book app': 'App buchen',
  'Book free trial': 'App testen',
  image: 'Bild',
  'There is no result for the provided search phrase.':
    'Es gibt kein Ergebnis für den angegebenen Suchbegriff.',
  'Please select a stream to view the containing products.':
    'Bitte wähle einen Stream aus, um die darin enthaltenen Produkte anzuzeigen.',
  'The selected stream does not contain any products.':
    'Der ausgewählte Stream enthält kein Produkte.',
  'Please enter a search term to see the results.':
    'Bitte gib einen Suchbegriff ein, um das Ergebnis zu sehen.',
  Mode: 'Modus',
  Reason: 'Grund',
  'Your preview is having a break': 'Deine Preview macht eine Pause',
  'Open a Landing page, Category or other in the Makaira Page Editor, to see a preview here again. Attention: The preview is only connected with the tab it was opened in originally!':
    'Öffne eine Landingpage, Kategorie oder ähnliches im Makaira Page Editor, um hier wieder eine Preview zu sehen. Hinweis: Die Preview ist nur mit dem Tab verknüpft, in dem sie ursprünglich geöffnet wurde!',
  'Personalization Settings': 'Personalisierungs-Einstellungen',
  'Enable Makaira Personalization': 'Makaira Personalisierung aktivieren',
  'Enable External Personalization': 'Externe Personalisierung aktivieren',
  'External Personalization Service URL':
    'URL vom externen Personalisierungsservice',
  'More information can be found in our documentation':
    'Mehr Informationen können in unsere Dokumentation gefunden werden',
  'Search Component': 'Komponente suchen',
  'Enter searchphrase...': 'Suchwort eingeben …',
  'No products found': 'Keine Produkte gefunden',
  'Enter a search word or choose a category on the left to see a product list and active/deactivate the Personalization with the switch above to see the effect.':
    'Gib ein Suchwort ein oder wähle eine Kategorie links um eine Produktliste zu sehen und aktiviere/deaktiviere die Personalisierung mit dem Schalter oben um den Effekt zu sehen',
  'Open app': 'App öffnen',
  'Show app information': 'App-Details anzeigen',
  'Start 30 days trial': 'Jetzt für 30 Tage testen',
  'Information about the app': 'App-Details',
  'Detailed information': 'Detaillierte Informationen',
  'There was an error while starting your trial. Please try again.':
    'Beim Buchen der Trial ist ein Fehler aufgetreten. Bitte probiere es noch einmal.',
  'The Page Editor is the heart for creating content in Makaira. The editor enables you to easily create landing pages, enrich entities like categories or manufacturers with content or create content snippets that could be reused on certain pages.':
    'Der Page Editor ist das Herzstück für die Erstellung von Inhalten in Makaira. Mit dem Editor kannst du ganz einfach Landing Pages erstellen, Entitäten wie Kategorien oder Hersteller mit Inhalten anreichern oder Content Snippets erstellen, die auf bestimmten Seiten wiederverwendet werden können.',
  'Using the Menu Editor the main menu structure of the storefront can be defined. Menu Editor section also allows us setting up sub menues or sub contents under particular menu.':
    'Mit dem Menü-Editor kann die Hauptmenüstruktur der Storefront definiert werden. Der Abschnitt des Menü-Editors ermöglicht auch die Einrichtung von Untermenüs oder Unterinhalten unter einem bestimmten Menü.',
  'The "A/B Experiments" module makes it possible on the one hand to test configurations of Makaira in a data-driven manner and on the other hand to play out different versions in the frontend. For example, different buyboxes or different page structures can be tested against each other.':
    'Das Modul "A/B Experimente" ermöglicht es, einerseits Konfigurationen von Makaira datengetrieben zu testen und andererseits verschiedene Versionen im Frontend auszuspielen. So können beispielsweise verschiedene Buyboxen oder unterschiedliche Seitenstrukturen gegeneinander getestet werden.',
  "Machine learning is the study of user's behaviour under various circumstances which helps in presenting suitable findings and content to the users in the future. Let's start with an example: Let's assume that tablet users in Bavaria regularly buy leather pants in the afternoon more often than iPhone users in Berlin during the same period. The machine learning now learns that tablet users from Bavaria are much more likely to search for leather pants than our Berlin customers, for example. If the tracking data of a whole year is available, it is also decided here whether it is a seasonal business and leather pants should only be displayed further in front in certain periods or whether the behavior is to be observed throughout the year. If leather pants in Bavaria are no longer in fashion for some reason, this will of course also be detected by machine learning and the search engine will be adjusted accordingly. Such trend changes do not happen from one day to the next, of course, and so the machine learning will slowly adapt here.":
    'Unter maschinellem Lernen versteht man die Untersuchung des Nutzerverhaltens unter verschiedenen Umständen, was dazu beiträgt, den Nutzern in Zukunft geeignete Ergebnisse und Inhalte zu präsentieren. Lass uns mit einem Beispiel beginnen: Nehmen wir an, dass Tablet-Nutzer in Bayern regelmäßig nachmittags häufiger eine Lederhose kaufen als iPhone-Nutzer in Berlin im gleichen Zeitraum. Das maschinelle Lernen lernt nun, dass Tablet-Nutzer aus Bayern viel häufiger nach Lederhosen suchen als zum Beispiel unsere Berliner Kunden. Liegen die Trackingdaten eines ganzen Jahres vor, wird auch hier entschieden, ob es sich um ein Saisongeschäft handelt und Lederhosen nur in bestimmten Zeiträumen weiter vorne angezeigt werden sollen oder ob das Verhalten ganzjährig zu beobachten ist. Wenn Lederhosen in Bayern aus irgendeinem Grund nicht mehr in Mode sind, wird dies natürlich auch durch maschinelles Lernen erkannt und die Suchmaschine entsprechend angepasst. Solche Trendwechsel passieren natürlich nicht von heute auf morgen, und so wird sich das maschinelle Lernen hier langsam anpassen.',
  'The "Advertising banner" module allows you to configure images of advertisement to be displayed on list pages in addition to the regular results.':
    'Mit dem Modul "Werbebanner" kannst du Bilder von Werbeanzeigen konfigurieren, die auf den Listenseiten zusätzlich zu den normalen Ergebnissen angezeigt werden.',
  'Advertising space is intended to advertise or prioritize products among relevant search results. With the help of advertising space, Makaira offers the possibility of placing targeted products, manufacturers and categories on selected positions of the search results and/or categories with the "Advertising Places" module.':
    'Werbeplätze dienen dazu, Produkte unter relevanten Suchergebnissen zu bewerben oder zu priorisieren. Mit Hilfe von Werbeflächen bietet Makaira die Möglichkeit, gezielt Produkte, Hersteller und Kategorien auf ausgewählten Positionen der Suchergebnisse und/oder Kategorien mit dem Modul "Werbeplätze" zu platzieren.',
  'Smart Bundles allows you to create bundles of related products based on various criteria. Through the bundles you increase the shopping cart and reduce returns, because only matching products can be added to the cart. In this way, matching outfits, rooms, equipment, etc. can be created according to the products.':
    'Smart Bundles ermöglicht es dir, Bündel von verwandten Produkten auf der Grundlage verschiedener Kriterien zu erstellen. Durch die Bundles erhöhst du den Warenkorb und reduzierst Retouren, da nur passende Produkte in den Warenkorb gelegt werden können. Auf diese Weise können zu den Produkten passende Outfits, Räume, Ausrüstungen usw. erstellt werden.',
  'Recommendations provides the shop users with recommended products e.g. on a product detail page. You can also create cart based or top seller recommendations. The possibilities are endless!':
    'Empfehlungen bieten den Shop-Benutzern empfohlene Produkte, z.B. auf einer Produkt-Detailseite. Du kannst auch Warenkorb-basierte oder Top-Seller-Empfehlungen erstellen. Die Möglichkeiten sind endlos!',
  'The search is the heart of Makaira and offers a variety of configuration for search functionality.':
    'Die Suche ist das Herzstück von Makaira und bietet eine Vielzahl von Konfigurationsmöglichkeiten für die Suchfunktionalität.',
  'The Monitoring app gives an overview of all searched terms. It shows both the terms with a result and those that do not return a result.':
    'Die Option Monitoring gibt einen Überblick über alle gesuchten Begriffe. Sie zeigt sowohl die Begriffe mit einem Ergebnis als auch diejenigen, die kein Ergebnis liefern.',
  'The Ranking Mix setting allows you to influence (boost) the order of category listing and search results based on additional parameters.':
    'Mit der Einstellung Ranking Mix kannst du die Reihenfolge der Kategorieauflistung und der Suchergebnisse anhand zusätzlicher Parameter beeinflussen (verstärken).',
  'In order for filters to be displayed in the store frontend for restricting search results, they must first be configured in Makaira. Filters can be temporarily disabled. Enabled filters are displayed by default for all search result sets where they can further narrow the set.':
    'Damit Filter zur Einschränkung von Suchergebnissen im Store-Frontend angezeigt werden können, müssen sie zunächst in Makaira konfiguriert werden. Filter können vorübergehend deaktiviert werden. Aktivierte Filter werden standardmäßig für alle Suchergebnismengen angezeigt, wo sie die Menge weiter einschränken können.',
  'Product streams allow the creation of filtered views on the product data. A common use case for streams is, for example, the provision of dynamic categories as part of promotional offers where a discount is given on selected brands, colors, sizes, etc.':
    'Produkt-Streams ermöglichen die Erstellung von gefilterten Ansichten der Produktdaten. Ein gängiger Anwendungsfall für Streams ist zum Beispiel die Bereitstellung dynamischer Kategorien als Teil von Werbeangeboten, bei denen ein Rabatt auf ausgewählte Marken, Farben, Größen usw. gewährt wird.',
  'Feeds of relevant articles are needed for external sales portals (e.g. Google, Facebook etc). You can generate such feeds easily and quickly in Makaira with the help of feed engine.':
    'Feeds relevanter Artikel werden für externe Verkaufsportale (z.B. Google, Facebook etc.) benötigt. Du kannst solche Feeds in Makaira mit Hilfe der Feed-Engine einfach und schnell erzeugen.',
  'Redirects allows you to redirect certain URLs to a targeted URL.':
    'Mit Umleitungen kannst du bestimmte URLs auf eine bestimmte URL umleiten.',
  "With search redirects you're able to direct users to a certain category or page via search functionality.":
    'Mit Suchweiterleitungen kannst du Nutzer über die Suchfunktion zu einer bestimmten Kategorie oder Seite leiten.',
  'The "Index and Importer" application gives you access to the core of Makaira: constantly updated product data. You can get insides about currently active and archived indices, about configured importers, or about the current status of the import process. You can also specify details of the import process like sources, scheduled index rebuilds or notification options for possible occurring errors.':
    'Die Anwendung "Index und Importer" gibt dir Zugriff auf das Herzstück von Makaira: ständig aktualisierte Produktdaten. Du kannst dich über aktive und archivierte Indizes, über konfigurierte Importer oder über den aktuellen Status des Importprozesses informieren. Darüber hinaus kannst du Details zum Importprozess festlegen, wie z.B. Quellen, geplante Index-Neuaufbauten oder Benachrichtigungsoptionen für eventuell auftretende Fehler.',
  'The Data Inspector allows you to perform searches directly in the Makaira API as well as display the products from a stream. The returned results can be used as a preview or as a test whether, for example, your modifier works as desired.':
    'Mit dem Data Inspector kannst du direkt in der Makaira API suchen und die Produkte aus einem Stream anzeigen. Die zurückgegebenen Ergebnisse können als Vorschau oder als Test verwendet werden, ob z. B. dein Modifikator wie gewünscht funktioniert.',
  'With this app you can explicitly define which product fields are public available to the storefront.':
    'Mit dieser App kannst du explizit festlegen, welche Produktfelder für die Storefront öffentlich zugänglich sind.',
  'The component editor offers the possibility to create your own placeable components in addition to the standard components, in order to insert and fill them in the Page Editor.':
    'Der Komponenteneditor bietet die Möglichkeit, zusätzlich zu den Standardkomponenten eigene platzierbare Komponenten zu erstellen, um diese im Page Editor einzufügen und zu füllen.',
  'This app will list all categories that were imported by Makaira from your shop system. You have here the option to assign custom streams to them and adjust displayed filters.':
    'Diese App listet alle Kategorien auf, die von Makaira aus deinem Shopsystem importiert wurden. Du hast hier die Möglichkeit, ihnen benutzerdefinierte Streams zuzuweisen und die angezeigten Filter anzupassen.',
  'Product Streams': 'Produkt-Streams',
  'Search Monitoring': 'Such-Monitoring',
  'Product Feeds': 'Produkt-Feeds',
  'Advertising Banners': 'Werbebanner',
  'Advertising Spaces': 'Werbeplätze',
  'Welcome to Makaira!': 'Willkommen bei Makaira!',
  'Personalization Checks could not be executed':
    'Personalisierungs-Checks konnten nicht ausgeführt werden',
  'last day': 'letzter Tag',
  'An error occurred whilst validating app credentials.':
    'Beim Validieren der App-Credentials ist ein Fehler aufgetreten.',
  'No application with the given slug exists.':
    'Keine Applikation mit dem gegebenen Slug existiert.',
  'Open Changes': 'Offene Änderungen',
  'Count of open changes (documents) that still have to be imported into Makaira, to be in sync with your shop':
    'Anzahl der Änderungen (Dokumente) die noch in Makaira importiert werden müssen, um mit deinem Shop synchron zu sein',
  'highest revision number in Makaira index':
    'höchste Revisionsnummer im Makaira Index',
  'Hey, nice to see you!': 'Hi, schön dass du hier bist!',
  "Let's get started!": 'Lass uns loslegen!',
  'We recommend to watch the video on the left to get a good insight into the capabilities of Makaira. If you need any additional information you can always check out our documentation below.':
    'Wir empfehlen dir das Video auf der linken Seite anzuschauen um einen Einblick in die Funktionalitäten von Makaira zu bekommen. Wenn du weitere Infos benötigst, schau gerne in unsere Dokumentation.',
  'Connect your shop system': 'Shopsystem verbinden',
  "Don't show this again": 'Nicht mehr anzeigen',
  close: 'schließen',
  'Order Value': 'Bestellwert',
  'Conversion Rate': 'Conversion Rate',
  'Unique Visitors': 'Eindeutige Besucher',
  'in the last 7 days': 'in den letzten 7 Tagen',
  'A/B Performance': 'A/B Performanz',
  'less revenue': 'weniger Umsatz',
  'more revenue': 'mehr Umsatz',
  'no test running': 'kein laufender Test',
  'no Tracking availabe': 'kein Tracking verfügbar',
  'You are configuring': 'Du konfigurierst',
  'Set branch for auto-deploy': 'Branch für Auto-Deployments',
  When: 'Wann',
  'View build log': 'Build-Log anzeigen',
  'Pending & Previous Builds': 'Laufende & Ältere Builds',
  failed: 'fehlgeschlagen',
  succeeded: 'erfolgreich',
  'A new build was triggered': 'Ein neuer Build wurde gestartet',
  'Your changes will be in effect after the build has finished successfully!':
    'Deine Änderungen sind sichtbar, sobald der Build erfolgreich durchgelaufen ist.',
  'Environment Variables': 'Environment Variablen',
  'Variable Name': 'Variablen-Name',
  'Variable Value': 'Variablen-Wert',
  'Fixed variables': 'Feste Variablen',
  'Copy all variables': 'Alle Variablen kopieren',
  'Add environment variable': 'Environment Variable hinzufügen',
  Password: 'Passwort',
  'Add user': 'Nutzer hinzufügen',
  'Switching off authentication?': 'Authentifikation deaktivieren?',
  'Ooops, go back': 'Uuups, lieber nicht',
  'Yes, go public': 'Ja, jetzt veröffentlichen!',
  'Storefront is public': 'Storefont ist öffentlich',
  'Click to enable authentication':
    'Klicke um die Authentifikation zu aktivieren',
  'Authentication / BasicAuth': 'Authentifikation / BasicAuth',
  'Active domains': 'Aktive Domains',
  'Domain name': 'Domain Name',
  'Certificate status': 'Zertifikat-Status',
  'Certificate issued': 'Zertifikat gültig',
  'Default Domain, cannot be deleted':
    'Standard Domain, kann nicht gelöscht werden',
  Error: 'Fehler',
  'Add a domain': 'Domain hinzufügen',
  'Proceed to step 2': 'Nächster Schritt',
  'Snippet for DNS configuration. Add as CNAME entry':
    'Ziel für die DNS-Konfiguration. Füge folgenden CNAME Eintrag ein:',
  'We check now for your DNS entry, this can take up to 2 minutes':
    'Wir prüfen jetzt deinen DNS Eintrag, das kann bis zu 2 Minuten dauern',
  'The DNS entry is missing': 'Der DNS Eintrag kann nicht gefunden werden',
  'Are you aware of the fact that': 'Ist dir bewusst dass du hiermit',
  'your storefront is public available and all users and passwords will be deleted?':
    'die Storefront öffentlich zugänglich machst und alle Nutzer sowie Passwörter gelöscht werden?',
  noStorefrontDescription: (
    <>
      Du hast noch keine Storefront, aber das lässt sich schnell ändern.
      <br />
      Kontaktiere uns einfach über{' '}
      <a href="mailto:support@makaira.io">support@makaira.io</a> und bekomme
      deine eigene Makaira-gehostete Storefront.
    </>
  ),
  errorStorefrontDescription: (errorId) => (
    <>
      Deine Storefront Einstellungen konnten nicht geladen werden, aufgrund
      eines Problems.
      <br />
      Bitte lade die Seite neu und versuche es noch einmal.
      <br />
      Falls das Problem damit nicht behoben ist, melde dich bitte über{' '}
      <a href="mailto:support@makaira.io">support@makaira.io</a> bei uns.
      <br />
      Fehlernummer {errorId}
    </>
  ),
  externalStorefrontDescription: (
    <>
      Deine Storefront wird aktuell nicht in der Makaira-Infrastruktur gehostet,
      <br />
      bitte kontaktiere deinen Hoster um Einstellungen an der Storefront
      vorzunehmen.
      <br />
      Wenn du gerne zu uns umziehen möchtest, um direkten Zugriff auf all deine
      Storefront-Einstellungen zu haben,
      <br />
      melde dich gerne bei{' '}
      <a href="mailto:support@makaira.io">support@makaira.io</a>
    </>
  ),
  'Domain has been successfully removed.': 'Domain wurde erfolgreich entfernt',
  'Enter a category ...': 'Kategorie eingeben ...',
  'Enter a manufacturer ...': 'Herstellerin eingeben ...',
  'Close log view': 'Build-Log schließen',
  loading: 'lädt',
  'Authentication is active': 'Authentifikation ist aktiviert',
  'Click to disable authentication. This makes the storefront publicly available':
    'Klicke um die Authentifikation zu deaktivieren. Dies macht die Storefront öffentlich zugänglich.',
  'password is required': 'Passwort wird benötigt',
  'username is required': 'Nutzername wird benötigt',
  'only alphanumeric characters are allowed':
    'nur alphanumerische Zeichen sind erlaubt',
  'only lowercase letters, dots, numbers and hyphen (-) are allowed':
    'nur Kleinbuchstaben, Punkte, Zahlen und Kurzstriche (-) sind erlaubt',
  'Snippet for DNS configuration. Add as a CNAME entry':
    'Snippet für die DNS-Konfiguration. Als CNAME-Eintrag hinzufügen',
  'Done adding DNS entry': 'DNS-Eintrag hinzugefügt',
  'Something went wrong refreshing the domains':
    'Beim Aktualisieren der Domains ist etwas schief gelaufen',
  'only alphanumeric characters and the underscore are allowed':
    'nur alphanumerische Zeichen und der Unterstrich sind erlaubt',
  'should not begin with a double underscore':
    'darf nicht mit einem doppelten Unterstrich beginnen',
  'Queued & Waiting': 'In Warteschlange',
  'Insert content from': 'Inhalt kopieren von',
  'Insert content from…': 'Inhalt kopieren von…',
  duplicate_modal_title: (count) =>
    count === 1
      ? `Möchtest du diesen Feed duplizieren?`
      : `Möchtest du ${count} Feeds duplizieren?`,
  'Add feed': 'Feed hinzufügen',
  'Link copied!': 'Link kopiert!',
  innerStreamDescription: (
    <>
      Produktvarianten, die Kinder des obigen <b>Hauptprodukt-Streams</b> sind
      und <br />
      <b>diesem Filter-Stream entsprechen</b>, werden exportiert
    </>
  ),
  'File Format': 'Dateiformat',
  'Stream used': 'Verwendeter Stream',
  'Fetching of KPI went wrong.': 'Abfragen der KPI fehlgeschlagen.',
  'no Tracking available': 'Kein Tracking verfügbar',
  'Order value': 'Bestellwert',
  'Install our tracking to display KPI':
    'Installiere unser Tracking um KPI anzuzeigen',
  'Verify Tracking': 'Tracking überprüfen',
  'Conversion rate': 'Conversion Rate',
  Visitors: 'Besucher',
  'please enter a valid domain': 'Bitte gib eine gültige Domain ein',
  'hostname is required': 'Hostname ist erforderlich',
  'You have unsaved changes. Are you sure you want to leave?':
    'Du hast ungespeicherte Änderungen. Bist du sicher, dass du Seite verlassen möchtest?',
  'Create a Feed to generate a file of relevant articles to\nimport them into external sales portals (e.g. Google, Facebook etc).':
    'Erstelle einen Feed, um eine Datei mit relevanten Artikeln zu generieren\nund diese in externe Verkaufsportale (z. B. Google, Facebook usw.) zu importieren.',
  'No feed could be found.': 'Es konnte kein Feed gefunden werden.',
  'Feed Structure': 'Feed-Struktur',
  'Add field': 'Feld hinzufügen',
  'Your code': 'Dein Code',
  'Product/Variant field': 'Produkt/Varianten-Feld',
  'Which product would you like to preview?':
    'Für welches Produkt möchtest du dir eine Vorschau ansehen?',
  'we only show the first 3 products to your search for a better overview.':
    'Zur Einfachheit zeigen wir lediglich die ersten 3 Produkte zu deiner Suche',
  'Copy URL for': 'URL kopieren für',
  'Download for': 'Herunterladen von',
  'Show header and footer code': 'Header- und Footer-Code anzeigen',
  'Click to generate a token': 'Klicke um einen Token zu generieren',
  'Feed Content': 'Feed Content',
  'Choose the product stream to populate the feed':
    'Wähle einen Produkt-Stream, um den Feed zu befüllen',
  'Export options': 'Export-Einstellungen',
  'Export products': 'Produkte exportieren',
  'include in export': 'zu Export hinzufügen',
  'only export when no variants are available':
    'nur exportieren, wenn keine Varianten vorhanden sind',
  'Export product variants': 'Produkt-Varianten exportieren',
  'All variants': 'Alle Varianten',
  'include header': 'Header hinzufügen',
  Separator: 'Trennzeichen für Felder',
  Wrapper: 'Feld-Wrapper',
  'Line-End': 'Zeichenfolge für Zeilenende',
  'Optional Variables': 'optionale Variablen',
  'The stream does not contain documents':
    'Der ausgewählte Produkt-Stream enthält keine Dokumente',
  'Something went wrong fetching feed preview…':
    'Beim Abrufen der Feed-Vorschau ist etwas schief gelaufen…',
  'Something went wrong fetching field validate…':
    'Beim Abrufen der Feldüberprüfung ist etwas schief gelaufen…',
  'Feed could not be loaded.': 'Feed konnte nicht geladen werden.',
  'Something went wrong loading the feed listing.':
    'Beim Laden der Feed-Liste ist ein Fehler aufgetreten.',
  'your feed content': 'Dein Feed-Inhalt',
  'No value': 'Kein Wert',
  'Comma (,)': 'Komma (,)',
  'Single quotes': 'Einfache Anführungszeichen',
  'Double quotes': 'Doppelte Anführungszeichen',
  kpi_beta_label: (
    <>
      Dies ist eine Beta-Funktion, die nur für <b>administrative</b> Benutzer
      sichtbar ist! Wenn Sie Fragen zur den dargestellten Zahlen haben, nehmen
      Sie bitte Kontakt mit uns auf und wir werden diese gemeinsam optimieren.
    </>
  ),
  'Reset to default code': 'Zurücksetzen auf Standard-Code',
  'Something went wrong taking over the lock':
    'Bei der Übernahme der Sperre ist etwas schief gelaufen.',
  'Page/Snippet taken over.': 'Seite/Snippet wurde übernommen',
  'Page/Snippet locked.': 'Seite/Snippet gesperrt',
  'Back to listing': 'Zurück zur Übersicht',
  'No, back to listing': 'Nein, zurück zur Übersicht',
  'Yes, take over': 'Ja, übernehmen',
  'has taken over and currently edits the content. Your changes were saved on a draft revision!':
    'hat die Sperre übernommen und bearbeitet aktuell den Inhalt. Deine ungespeicherten Änderungen sind als Revision gespeichert worden.',
  'is currently editing this content. Do you want to take over?':
    'bearbeitet aktuell den Inhalt. Möchtest du übernehmen?',
  'Attention:': 'Achtung:',
  'If you take over the changes of the other person are lost!':
    'Wenn du übernimmst gehen die Änderungen der anderen Person verloren!',
  'Last seen:': 'Zuletzt gesehen:',
  'There are no logs available yet. As soon as there are logs available they are shown here.':
    'Es sind noch keine Log Einträge verfügbar. Sobald diese verfügbar sind, werden sie hier angezeigt.',
  rankingMix_insert: 'Neuheiten',
  rankingMix_profit_margin: 'Marge',
  rankingMix_rating: 'Bewertungen',
  rankingMix_revenue: 'Umsatz',
  rankingMix_sold: 'Anzahl der Verkäufe',
  rankingMix_view: 'Anzahl der Detailseitenaufrufe',
  rankingMix_basket: 'In den Warenkorb gelegt',
  rankingMix_new: 'Neuheiten',
  'Use Rankingmix for search results':
    'Rankingmix für Suchergebnisse verwenden',
  'Show out of stock items at the end of the list':
    'Nicht vorrätige Artikel am Ende der Liste anzeigen',
  'Use Rankingmix for categories': 'Rankingmix für Kategorien verwenden',
  'Enter a search word or choose a category above to see a product list to preview your selected ranking mix.':
    'Gib einen Suchbegriff ein oder wähle oben eine Kategorie aus, um eine Vorschau für den ausgewählten Ranking-Mix zu sehen.',
  'Preview the Rankingmix for…': 'Vorschau des Rankingmix für…',
  'Since all boosting data stored on the products are identical, the ranking mix will have no influence.':
    'Alle an den Produkten hinterlegten Boosting Daten identisch sind, somit wird der Ranking Mix keinen Einfluß haben.',
  'The boosting data for this metric are outside the valid value range (0-1).':
    'Die Boosting Daten für die Metrik ausserhalb des gültigen Werte Bereichs (0-1).',
  'ReasonExplain_Not enough data for ranking mix.':
    'Weniger als 50% der Produkte verfügen über Boosting Daten',
  'No ranking mix data.': 'Schlechte Datenqualität',
  'Low amount of data': 'Geringe Datenmenge',
  'Invalid boosting data': 'Ungültige Boosting Daten.',
  Upcoming: 'Anstehend',
  Archive: 'Archiviert',
  'Add test': 'Test erstellen',
  draft_ab_status: 'Entwurf',
  running_ab_status: 'läuft',
  scheduled_ab_status: 'geplant',
  done_ab_status: 'done',
  Including: 'Beinhaltet',
  'Start on or after…': 'Beginnt am oder nach…',
  'End on or before…': 'Endet am oder vor…',
  Start: 'Beginn',
  Duration: 'Dauer',
  Segments: 'Segmente',
  'Maximum change for…': 'Maximale Änderung für…',
  'Products purchased': 'Gekaufte Produkte',
  'Total viewed': 'Gesamte Ansichten',
  'This field is required.': 'Dieses Feld wird benötigt.',
  within: 'innerhalb',
  'between dates': 'zwischen Daten',
  'All instances': 'Alle Instanzen',
  more: 'weitere',
  Role: 'Rolle',
  'All Users': 'Alle Nutzer',
  Email: 'E-Mail',
  'Search for email': 'Nach E-Mail suchen',
  multiple_role_user: (
    <>
      Cannot change role of user that have multiple domains.{' '}
      <a href="https://docs.makaira.io" target="_blank" rel="noreferrer">
        Read more
      </a>
    </>
  ),
  'Invite User': 'Nutzer einladen',
  'Resend invitation mail': 'Einladung erneut versenden',
  'Invitation mail was sent to user. Attention: The activation link is only valid for 24 hours.':
    'Einladungsmail wurde versendet. Achtung: Der Einladungslink ist nur für 24 Stunden gültig.',
  'Invitation was sent. User can directly log in.':
    'Einladung wurde gesendet. Der Nutzer kann sich direkt einloggen.',
  'Display timeline from…': 'Timeline anzeigen vom…',
  '…until': '…bis',
  'Create a test': 'Erstelle einen Test',
  'Type of test': 'Test-Art',
  'Number of versions to test': 'Anzahl der Versionen zum Testen',
  'Name your test': 'Benenne deinen Test',
  '2 versions: A-B test': '2 Versionen: A/B-Test',
  '3 versions: A-B-C test': '3 Versionen: A/B/C-Test',
  'Proceed to test configuration': 'Weiter zur Test-Konfiguration',
  'Please finish the test setup above first…':
    'Bitte beende zuerst die obige Testeinrichtung…',
  'This area will be editable once your test is configured':
    'Dieser Bereich kann bearbeitet werden, sobald der Test konfiguriert ist.',
  'Traffic distribution': 'Traffic-Verteilung',
  'Click on a lock symbol to freeze a traffic channel’s percentage.':
    'Klicke auf ein Schloss-Symbol, um den Prozentsatz eines Traffic-Kanals einzufrieren.',
  'The other channels will always interact to reach a 100% traffic total.':
    'Die anderen Kanäle werden immer interagieren, um einen Gesamt-Traffic von 100% zu erreichen.',
  'Start test': 'Test starten',
  Manually: 'Manuell',
  'Stop the test…': 'Test beenden…',
  '… at': '… bei',
  'statistical relevance on': 'statistische Relevanz für',
  '… manually': '… manuell',
  'Create test': 'Test erstellen',
  ab_frontend_description: (
    <>
      Schaue in die{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://docs.makaira.io/docs/ab-experiments#ab-testing-in-the-frontend"
      >
        Dokumentation
      </a>
      , um zu lernen wie man Frontend-Tests implementiert.
    </>
  ),
  ab_personalization_description: (
    <>
      Schaue in die{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://docs.makaira.io/docs/ab-experiments"
      >
        Dokumentation
      </a>{' '}
      , um mehr über AB-Tests zu erfahren.
    </>
  ),
  'Personalization state': 'Personalisierung aktiv?',
  ON: 'AN',
  OFF: 'AUS',
  'Stop test': 'Test stoppen',
  'Save configuration': 'Konfiguration speichern',
  'Discard changes': 'Änderungen verwerfen',
  'Create an A/B-Test to test on the one hand configurations of Makaira in a data-driven manner\nand on the other hand to play out different versions in the frontend.':
    'Erstelle einen A/B-Test, um zum einen Konfigurationen von Makaira datengetrieben zu testen\nund zum anderen verschiedene Versionen im Frontend auszuspielen.',
  'You are not able to modify the personalization as long as a AB-test for the personalization is running!':
    'Die Personalisierung kann nicht geändert werden, solange ein AB-Test für die Personalisierung läuft!',
  'AB-test for the personalization is running!':
    'Ein AB-Test für die Personalisierung läuft!',
  expired: 'abgelaufen',
  'Generate breadcrumbs and the sidebar navigation entry':
    'Erzeugen von Breadcrumbs und der Navigation in der Sidebar',
  Weather: 'Wetter',
  Rainy: 'Regnerisch',
  Sunny: 'Sonnig',
  'Consider actual weather data in the region of the user':
    'Berücksichtigung aktueller Wetterdaten in der Region des Nutzers',
  Ignore: 'Nicht berücksichtigen',
  'Loading Persistence Layer information…':
    'Lade Persistence Layer Informationen…',
  'We do not have any data for this instance stored in the Persistence Layer.':
    'Wir haben keine Daten für diese Instanz im Persistence Layer gespeichert.',
  'An error occurred while trying to fetch the data from the Persistence Layer.':
    'Beim Abrufen der Daten aus dem Persistence Layer ist ein Fehler aufgetreten.',
  'Source URL has to be filled.': 'Source-URL muss ausgefüllt werden.',
  'Source-URL has to be a valid URL.': 'Source-URL muss eine gültige URL sein.',
  'less than 30% of the amount': 'weniger als 30 % des Betrags',
  'equals same year': 'entspricht demselben Jahr',
  'Interesting products in the same year':
    'Interessante Produkte im selben Jahr',
  Accessories: 'Zubehör',
  'New products': 'Gerade angekommen',
  'Other users also bought': 'Beliebte Produkte anderer Nutzer',
  'The recommendation is based on products that are bought together based on the purchase date.':
    'Die Recommendation basiert auf Produkten, die zusammen gekauft werden, basierend auf dem Kaufdatum.',
  'The recommendation is based on products that are bought together and the recommended products are in a lower price range.':
    'Die Recommendation basiert auf Produkten, die zusammen gekauft werden, und deren Preis in einer niedrigeren Preisklasse liegen.',
  'The recommendation is based on products that are bought together and the number of sold items.':
    'Die Empfehlung basiert auf Produkten, die zusammen gekauft werden, und auf der Anzahl der verkauften Produkte.',
  'Shows products that were added to the cart by other users.':
    'Zeigt Produkte an, die von anderen Benutzern in den Warenkorb gelegt wurden.',
  import_unsupport_language_warning: (languages = []) => (
    <>
      Sie möchten die Sprache{' '}
      {languages.map(
        (lang, index) =>
          `"${lang.toUpperCase()}"${index !== languages.length - 1 ? ',' : ''} `
      )}{' '}
      importieren, die derzeit von Makaira nicht unterstützt wird. Bitte wenden
      Sie sich an{' '}
      <a href="https://docs.makaira.io" target="_blank" rel="noreferrer">
        support@makaira.io
      </a>{' '}
      und die Sprache wird hinzugefügt.
    </>
  ),
  'If you experience trouble, please contact the App developer.':
    'Wenn Sie Probleme haben, wenden Sie sich bitte an den App-Entwickler.',
  'Preview the recommendation for...': 'Vorschau der Empfehlung für...',
  Presets: 'Templates',
  'Select products that are used for the preview':
    'Wählen Sie die Produkte, die für die Vorschau verwendet werden',
  'Select products to see the preview.':
    'Wählen Sie Produkte aus, um die Vorschau zu sehen.',
  'Identifier for your front-end templates':
    'Bezeichner für Ihre Frontend-Vorlagen',
  'Recommendations based on …': 'Empfehlungen basierend auf ...',
  'Filter products': 'Produkte filtern',
  'Add filter': 'Filter hinzufügen',
  'Sort products': 'Produkte sortieren',
  'Use Ranking Mix': 'Ranking-Mix verwenden',
  'Diversify Results': 'Ergebnisse diversifizieren',
  'Boost with similar attributes': 'Mit ähnlichen Attributen boosten',
  'Add boosting attribute': 'Boosting-Attribut hinzufügen',
  Display: 'Anzeige',
  "Randomise the results' order":
    'Die Reihenfolge der Ergebnisse zufällig festlegen',
  'Create a filter rule': 'Eine Filterregel erstellen',
  'Choose a product data field': 'Wählen Sie ein Produktdatenfeld',
  'Fields to choose from ...': 'Felder zur Auswahl ...',
  'or just of type': 'oder nur vom Typ',
  'Create a boosting rule': 'Eine Boostingregel erstellen',
  'Define the rule': 'Definieren Sie die Regel',
  'All Products input': 'Alle Produkte eingeben',
  'Filtered Products output': 'Ausgabe gefilterter Produkte',
  'Highlight affected settings/content':
    'Betroffene Einstellungen/Inhalte hervorheben',
  'Save versions': 'Versionen speichern',
  'Versions updated!': 'Versionen aktualisiert!',
  'Failed to update versions.': 'Versionen konnten nicht aktualisiert werden.',
  'Fetching of the experiment results failed.':
    'Das Abrufen der Versuchsergebnisse ist fehlgeschlagen.',
  'Not enough data has been collected yet.':
    'Es wurden noch nicht genügend Daten gesammelt.',
  'When enough data is available, the results will be shown here.':
    'Sobald genügend Daten verfügbar sind, werden die Ergebnisse hier angezeigt.',
  'The selected variation could not be activated.':
    'Die ausgewählte Variation konnte nicht aktiviert werden.',
  Variation: 'Variation',
  'has been set live.': 'wurde als Live-Variate übernommen.',
  'There is a newer A/B-Test of this type, so no variation can be activated.':
    'Es gibt eine neueren A/B-Test dieses Typen, weshalb keine Variante als Live-Variante übernommen werden kann.',
  nb_visits: 'Besuche',
  nb_uniq_visitors: 'Eindeutige Besucher',
  nb_conversions: 'Gekaufte Produkte',
  nb_orders: 'Bestellungen',
  nb_orders_revenue: 'Umsatz',
  sum_visit_length: 'Besuchsdauer',
  bounce_count: 'Absprünge',
  average: 'pro Besuch',
  detected_effect: 'Erkannte Auswirkung',
  significance_rate: 'Statistische Signifikanz',
  'Traffic split': 'Traffic-Verteilung',
  'Display data for': 'Daten anzeigen für',
  'In graph displayed variants:': 'Im Graph angezeigte Varianten',
  'For version A the current productive settings are used.':
    'Für version A werden die aktuellen produktiven Einstellungen genutzt.',
  'The experiment could not be started because maximum five experiments of type frontent test can be active':
    'Der Test konnte nicht gestartet werden, da maximal fünf Experimente des Typs Frontent-Test parallel aktiv sein können',
  'The experiment could not be started because only one experiment of type persionalization can be active':
    'Der Test konnte nicht gestartet werden, da nur ein Experiment vom Typ Persionalisierung aktiv sein kann',
  'The experiment could not be started because only one experiment of type ranking mix can be active':
    'Der Test konnte nicht gestartet werden, da nur ein Experiment des Typs Ranking Mix aktiv sein kann',
  'The experiment could not be started because there is already a experiment running for this recommendation':
    'Der Test konnte nicht gestartet werden, da bereits ein Experiment für diese Recommendation aktiv ist',
  'What to include in your Search Results / Auto-Suggest':
    'Was in Ihre Suchergebnisse / Auto-Suggest einbezogen wird',
  'Which additional data should be displayed in the search result?':
    'Welche Daten sollen zusätzlich im Suchergebnis dargestellt werden?',
  'From which field should the term suggestions be loaded while entering a search expression?':
    'Aus welchem Feld sollen die Begriffsvorschläge während der Eingabe eines Suchbegriffs geladen werden?',
  'Where do you want to search?': 'Wo soll gesucht werden?',
  Destination: 'Ziel',
  'External link': 'Externer Link',
  'Valid for the following languages': 'Gültig für folgende Sprachen',
  'Search terms': '	Suchbegriffe',
  'Add all the words for this link, separate them by using a comma or a semicolon.':
    'Fügen alle Wörter für diesen Link ein, trennen Sie diese durch ein Komma oder ein Semikolon.',
  Activation: 'Aktivierung',
  'Use language specific titles from imported product attributes':
    'Sprachspezifische Titel aus importierten Produktattributen verwenden',
  'Depends upon': 'Hängt ab von',
  'Sort options by': 'Sortierung der Werte nach',
  'Manual sorting of the values': 'Manuelle Sortierung der Werte',
  'Show number of results': 'Anzahl der Ergebnisse anzeigen',
  'Put active filters to the top': 'Aktive Filter an den Anfang setzen',
  'Order of values': 'Sortierung der Werte',
  'Manual sorting': 'Manuelle Sortierung',
  'Contains...': 'Enthält...',
  Words: 'Wörter',
  'Add all the words for this redirect, separate them by using a comma or a semicolon.':
    'Fügen Sie alle Wörter für diese Weiterleitung ein, sie werden durch Komma oder Semikolon getrennt.',
  'Type of destination': 'Art des Ziels',
  'Choosen destination': 'Gewähltes Ziel',
  Redirect: 'Redirect',
  'New Redirect': 'Neuen Redirect',
  language_gb: 'United Kingdom',
  'Upload media label': 'Upload label',
  'You can give the upload fields custom labels (eg. wide, square, vertical)':
    'Sie können den Upload-Feldern eigene Bezeichnungen geben (z.B. breit, quadratisch, vertikal)',
  'Banner types': 'Banner-Typen',
  'You can configure banners: Choose a custom labels for easy identification and pick which upload fields should be visible available':
    'Sie können Banner konfigurieren: Wählen Sie eine benutzerdefinierte Beschriftung zur einfachen Identifizierung und legen Sie fest, welche Upload-Felder sichtbar sein sollen',
  Banners: 'Werbebanner',
  'Save all settings': 'Alle Einstellungen speichern',
  'Add an instance': 'Hinzufügen einer Instanz',
  'Instance name': 'Name der Instanz',
  'For now your instances list can only be updated by the support team.':
    'Im Moment kann die Liste Ihrer Instanzen nur vom Support-Team aktualisiert werden.',
  'I want to change an instance': 'Ich möchte eine Instanz ändern',
  'I want to delete an instance': 'Ich möchte eine Instanz löschen',
  'I want to add an instance': 'Ich möchte eine Instanz hinzufügen',
  'Preview URL': 'Vorschau URL',
  'We will contact you to verify that you submitted this request.':
    'Wir werden uns mit Ihnen in Verbindung setzen, um zu überprüfen, ob Sie diesen Antrag gestellt haben.',
  'We will contact you to verify that you submitted this request and inform you about the prices.':
    'Wir werden uns mit Ihnen in Verbindung setzen, um zu überprüfen, ob Sie diesen Antrag gestellt haben, und Sie über die Preise informieren.',
  'Assign the fields from the source system to the respective views':
    'Zuweisung der Felder aus dem Quellsystem zu den jeweiligen Views',
  'Choose public fields': 'Öffentliche Felder auswählen',
  'Make fields available': 'Felder verfügbar machen',
  Fields: 'Felder',
  'Add another field': 'Ein weiteres Feld hinzufügen',
  'Product List Page (PLP)': 'Produktlistenseite (PLP)',
  'Product Detail Page (PDP)': 'Produkt-Detailseite (PDP)',
  'Landing Page (LP)': 'Landing Page (LP)',
  downloadAnalyticsTooltip:
    'Lade eine CSV-Datei mit Tracking-Analysen herunter',
  'Start date before': 'Startdatum vor',
  'End date after': 'Enddatum nach',
  'Add banner': 'Werbebanner erstellen',
  'Schedule start': 'Startzeitpunkt',
  'Schedule end': 'Endzeitpunkt',
  'Displayed on': 'Angezeigt auf',
  from: 'ab',
  'Where?': 'Wo?',
  'Which manufacturer?': 'Welche Hersteller?',
  'Which categories?': 'Welche Kategorien?',
  'for these search terms (terminate expressions by "," or ";")':
    'für diese Suchbegriffe (Ausdrücke mit "," oder ";" trennen)',
  Link: 'Verknüpfung',
  Tracking: 'Verfolgung',
  'search result only': 'Nur Suchergebnis',
  'search suggestions only': 'Nur Suchvorschläge',
  'search results and suggestions': 'Suchergebnisse und Vorschläge',
  'Banner position': 'Banner position',
  'Preview the order': 'Vorschau der Bestellung',
  'time-based visibility can be changed':
    'Zeitgesteuerte Ausspielung kann geändert werden',
  'Category list': 'Kategorieliste',
  'Manufacturer list': 'Herstellerliste',
  'New banner': 'Neuen Werbebanner',
  'Time-based display': 'Zeitgesteuerte Ausspielung',
  'Set a start date and time': 'Startzeitpunkt',
  'Stop displaying': 'Endzeitpunkt',
  'toggle to set an end date': 'Umschalten, um ein Enddatum festzulegen',
  promo_document_heading: (type) => `Auswahl der zu bewerbenden ${type}`,
  promo_document_desc: (type) =>
    `Welche ${type} sollen auf den Listen beworben werden?`,
  'Your promoted': 'Beworbene',
  'Sort products by sort field': 'Produkte nach Sortierfeld ordnen',
  'Sort manually like below': 'Manuelle Sortierung wie unten',
  'Show all': 'Alle anzeigen',
  'Select language': 'Sprache auswählen',
  'Most frequently used search terms': 'Häufigste Suchbegriffe',
  'Last 6 months': 'Letzte 6 Monate',
  'Last 9 months': 'Letzte 9 Monate',
  'Last 12 months': 'Letzte 12 Monate',
  'Include bot traffic': '	Bot-Traffic einschließen',
  'Top search terms': 'Top Suchbegriffe',
  'Top failed search terms': 'Top Suchbegriffe ohne Ergebnis',
  'Download results': 'Ergebnisse downloaden',
  'Check term for special handling': 'Begriff für Sonderbehandlung prüfen',
  'Define how the search behaves on a certain search term.':
    'Definieren Sie, wie sich die Suche bei einem bestimmten Suchbegriff verhält.',
  'This search term is already defined':
    'Dieser Suchbegriff ist bereits definiert',
  'Show Synonym': 'Synonym anzeigen',
  'Show Antonym': 'Antonym anzeigen',
  'Number of hits': 'Anzahl Treffer',
  Filters: 'Filter',
  'Search a target': 'Ein Ziel suchen',
  'Add a redirect': 'Redirect hinzufügen',
  'Where should the redirect refer to?': 'Wohin soll der Redirect verweisen?',
  Priority: 'Priorität',
  'Delete public field now?': 'Öffentliches Feld jetzt löschen',
  'Base URL (used for Feeds, Sitemaps)':
    'Basis URL (Genutzt für Feeds und Sitemaps)',
  'Public fields': 'Öffentliche Felder',
  'Nothing found - just create a new stream or reset your search & filters above.':
    'Nichts gefunden - lege eine neue Produkt-Streams an oder setze deine Filter oben zurück.',
  'Used in': 'Benutzt in',
  'with Boosting': 'mit Boosting',
  'used in Categories': 'in Kategorien verwendet',
  'used in Feeds': 'in Feeds verwendet',
  'unused Streams': 'ungenutzte Streams',
  'Add Product Streams': 'Produkt-Streams hinzufügen',
  'Stream usage': 'Stream verwendung',
  'Add at least one condition': 'Fügen Sie mindestens eine Bedingung hinzu',
  Optional: 'optionale',
  'Used in...': 'Benutzt in...',
  'Copy Stream': 'Stream kopieren',
  'Stream content': 'Streaminhalt',
  'This stream is in use on ...': 'Der Stream wird genutzt in...',
  'Stream overwrite': 'Stream überschreiben',
  'Use a custom stream': 'Benutzerdefinierten Stream verwenden',
  'Default filters': 'Standardfilter',
  'Custom filters': 'Benutzerdefinierte Filter',
  'Which filters do you want to use?': 'Welche Filter sollen genutzt werden?',
  Hidden: 'Versteckt',
  Archived: 'Archiviert',
  start: 'Gerade gestartet',
  force_merge: 'Indexoptimierung',
  update_routing: 'Index verschieben',
  update_replicas: 'Indexreplikation aktivieren',
  set_alias: 'Mit aktivem Index tauschen',
  done: 'Aktiviert',
  'Custom Filter': 'Benutzerdefinierte Filter',
  'Categories with custom filter': 'Kategorien mit benutzerdefiniertem Filter',
  'Use search redirects': 'Such redirects anwenden',
  'Create Searchable Link': 'Suchbaren Link erstellen',
  'No synonyms found.': 'Keine Synonyme gefunden.',
  'No antonyms found.': 'Keine Antonyme gefunden.',
  'No filter found.': 'Keine Filter gefunden.',
  'No links found.': 'Keine Links gefunden.',
  'No redirects found.': 'Keine Redirects gefunden.',
  'Type of array elements': 'Typ der Array-Elemente',
  'Default value (optional)': 'Standardwert (optional)',
  'Number of options': 'Anzahl der Optionen',
  'App Support': 'App-Unterstützung',
  'Form element': 'Formular-Element',
  Instructions: 'Anweisungen',
  'Page Editor Components': 'Komponenten des Seiteneditors',
  'No fields created yet.': 'Noch keine Felder angelegt.',
  'A New Component...': 'Eine neue Komponente...',
  Reload: 'Neu laden',
  'Sorting filters': 'Sortierungsfilter',
  'Default sorting': 'Standardsortierung',
  'Custom sorting': 'Individuelle Sortierung',
  'Drag and Drop to sort the filters':
    'Ziehen und Ablegen zum Sortieren der Filter',
  'The Redirect module let you efficiently manage website redirections, seamlessly directing incoming traffic from specific URLs to designated target destinations':
    'Das Redirect Modul ermöglicht es, Website-Weiterleitungen effizient zu verwalten, indem eingehender Traffic von bestimmten URLs zu festgelegten Zielzielen umgeleitet wird.',
  'Show code snippet': 'Codeschnipsel anzeigen',
  'Document type': 'Dokumenttyp',
  Components: 'Komponenten',
  State: 'Status',
  'Not active': '	Nicht aktiv',
  Used: 'Genutzt',
  Unused: 'Ungenutzt',
  'Used in ...': 'Nutzung ...',
  'Drag form elements into the area to the right in order to create an input form for your component.':
    'Ziehen Sie Elemente in den rechten Bereich, um ein Eingabeformular für Ihre Komponente zu erstellen.',
  'Form Elements': 'Formular Elemente',
  'File-Upload': 'Datei-Upload',
  Number: 'Nummer',
  'Document-Types': 'Dokument-Typen',
  'Color Picker': 'Farbauswahl',
  Object: 'Objekt',
  'Conditional Section': 'Bedingter Bereich',
  'Form Builder': 'Formular-Editor',
  'Data Structure': 'Datenstruktur',
  'Drag form elements from the Library on the left into this area':
    'Ziehen Sie Elemente aus der Bibliothek auf der linken Seite',
  'or double click on them to add elements to the form':
    'in diesen Bereich oder doppelklicken',
  'Select an element or section in the middle to configure it here':
    'Wählen Sie ein Element oder einen Abschnitt in der Mitte, um es hier zu konfigurieren',
  'Mandatory field': 'Pflichtfeld',
  'Not deprecated': 'Nicht deprecated',
  'Set the condition to show this section in the tab on the right':
    'Legen Sie die Bedingung für die Anzeige dieses Abschnitts in der Registerkarte Logik auf der rechten Seite fest',
  'Place any element from the library on the left in this area ...':
    'Platzieren Sie ein beliebiges Element aus der Bibliothek auf der linken Seite in diesem Bereich ...',
  "Select a 'Select', 'Checkbox or 'Number' element to be the condition field":
    "Wählen Sie ein 'Select'-, 'Checkbox'- oder 'Number'-Element als Bedingungsfeld",
  'No element found': 'Kein Element gefunden',
  Condition: 'Bedingung',
  'Show this section': 'Zeige den Bereich an',
  sectionSavedSuccess: 'Sektion gespeichert!',
  sectionExists: (name) =>
    `Es existiert bereits eine Sektion mit dem Namen '${name}'! Bitte benennen Sie die Sektion um oder löschen Sie die existierende vor dem Speichern.`,
  'Section deleted!': 'Sektion gelöscht!',
  'Name is required!': 'Name ist erforderlich!',
  'A new secret key': 'Ein neuer geheimer Schlüssel',
  'Secret copied!': 'Schlüssel kopiert!',
  'Deleting a key will revoke all access rights and permissions immediately':
    'Das Löschen eines Schlüssels führt zum sofortigen Entzug aller Zugriffsrechte und Berechtigungen',
  'Permanently delete this key': 'Dauerhaftes Löschen dieses Schlüssels',
  'Permissions to access API endpoints': 'Berechtigungen für API Endpunkte',
  'No Permissions': 'Keine Berechtigungen',
  'Never expire': 'Niemals ablaufen',
  'Expire at': 'Verfallen am',
  'Nothing found - just create a new secret key or reset your search & filters above.':
    'Nichts gefunden - erstellen Sie einfach einen neuen geheimen Schlüssel oder setzen Sie Ihre Suche und Filter oben zurück.',
  'API Keys': 'API-Schlüssel',
  'Add API Key': 'API-Schlüssel hinzufügen',
  Expiration: 'Verfallsdatum',
  'Created By': 'Erstellt von',
  Key: 'Schlüssel',
  'Key copied!': 'Schlüssel kopiert!',
  'No role could be found.': 'Es konnte kein Rolle gefunden werden.',
  'Add a role': 'Rolle hinzufügen',
  'Role management': 'Rollen management',
  'Search a permission': 'Suche nach einer Erlaubnis',
  'User details': 'Benutzerdetails',
  'Remove access': 'Zugriff entfernen',
  'The user will no longer be able to access this account.':
    'Der Nutzer wird den Account nicht mehr benutzen können.',
  'Instances and roles': 'Instanzen und Rollen',
  'Which role should be assigned to this user?':
    'Welche Rolle soll diesem Benutzer zugewiesen werden?',
  Users: 'Nutzer',
  Roles: 'Rollen',
  'Please input at least three characters to search.':
    'Bitte mindestens 3 Zeichen eingeben',
  'Show permissions for': 'Zeige die Berechtigungen für',
  'Custom Role': 'Benutzerdefinierte Rolle',
  'Read permissions are GET request to the Makaira backend. Write permissions are POST, PUT or DELETE requests to the Makaira backend.':
    'Leseberechtigungen entsprechen GET Anfragen an das Makaira Backend. Schreibberechtigungen entsprechen datenverändernde POST, PUT oder DELETE Anfragen an das Makaira Backend.',
  'API Endpoint': 'API Endpunkt',
  Read: 'Lesen',
  Write: 'Schreiben',
  'Not enough permissions.': 'Nicht ausreichend Berechtigungen.',
  'Instances of': 'Instanzen von',
  'Here you can paste a component structure which you copied on the component list. The current structure will be replaced.':
    'Fügen Sie die kopierte Komponente ein, welche Sie von der Komponentenliste kopiert haben. Die aktuelle Struktur wird hierbei ersetzt.',
  'This is a default role and can not be changed!':
    'Dies ist eine Standardrolle und kann nicht geändert werden!',
  'Custom role': 'Benutzerdefinierte Rolle',
  'Default role': 'Standardrolle',
  'General Permission to read and write for all apps. This permission only exists for the default makaira roles.':
    'Allgemeine Berechtigung zum lesen und schreiben für alle Apps. Diese Berechtigung existiert nur für die Standard Makaira Rollen.',
  'Name already exists!': 'Name existiert bereits!',
  'The character strings “--” and “__” are not permitted in role names!':
    'Die Zeichenketten "--" und "__" sind in Rollennamen nicht erlaubt!',
  'Last Login': '	Letzter Login',
  'To add custom roles you need to book the Makaira Role Manager.':
    'Um benutzerdefinierte Rollen hinzuzufügen, müssen Sie den Makaira Role Manager buchen.',
  'All Instances': 'Alle Instanzen',
  'Consider text similarity on top':
    'Zusätzlich Textähnlichkeit berücksichtigen',
  'All other languages': 'Alle andere Sprachen',
  'Please enter a valid URL': 'Bitte gültige Url eingeben',
  'Search for endpoint': 'Nach Endpunkt suchen',
  Notes: 'Beschreibung',
  Days: 'Tage',
  'Select date': 'Wähle ein Datum',
  Never: 'Niemals',
}
