import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'

import HeadingMain from '../../../components/Headings/HeadingMain'
import Table, { Column } from '../../../components/Table'
import Text from '../../../components/Text'
import Checkbox from '../../../components/Checkbox'

import { SecretStore } from '../../../stores'

import { t } from '../../../utils'
import { Tooltip } from 'antd'
import Icon from '../../../components/Icon'
import ResourceTableFilter from '../../../components/ResourceTable/ResourceTableFilter'

function AccessPermission() {
  const {
    secretDetail,
    state,
    onChangePermission,
    isFetchingDetail,
    permissions,
  } = SecretStore

  const [search, setSearch] = useState('')

  const getPermissionKey = (name, action) => `${name}:${action}`

  const tableRows = useMemo(() => {
    const permissionKeys = permissions.reduce((prev, curr) => {
      let matched = curr.split(':')
      const action = matched.pop()
      const key = matched.join(':')

      if (!prev[key]) prev[key] = []

      prev[key].push(action)
      return prev
    }, {})

    return Object.keys(permissionKeys).map((name) => {
      const permissionKeyRead = getPermissionKey(name, 'read')
      const permissionKeyWrite = getPermissionKey(name, 'write')

      return {
        name,
        actions: permissionKeys[name],
        read: secretDetail.permissions?.includes(permissionKeyRead),
        write: secretDetail.permissions?.includes(permissionKeyWrite),
        permissionKeyRead,
        permissionKeyWrite,
      }
    })
  }, [permissions, secretDetail])

  return (
    <div>
      <HeadingMain>
        {t('Permissions to access API endpoints')}
        <Tooltip
          title={t(
            'Read permissions are GET request to the Makaira backend. Write permissions are POST, PUT or DELETE requests to the Makaira backend.'
          )}
        >
          <Icon
            className="resource-table__infor-icon"
            symbol="circle-info__solid"
            width={20}
            height={20}
          />
        </Tooltip>
      </HeadingMain>
      <ResourceTableFilter
        filters={[
          {
            type: 'search',
            title: t('Search for endpoint'),
            name: 'searchPhrase',
            onChange: ({ searchPhrase }) => setSearch(searchPhrase),
            placeholder: t('Search'),
          },
        ]}
      />
      <Table
        rowKey={(record) => record.id}
        data={tableRows.filter((row) => row.name.includes(search) || !search)}
        loading={isFetchingDetail || state === 'pending'}
        rowClassName="resource-table__row"
        emptyText={
          search ? 'Not found' : !(state === 'pending') ? 'No Permissions' : ''
        }
      >
        <Column
          title={t('API Endpoint')}
          className="mk-table-cell--bold"
          dataIndex="name"
          render={(name) => {
            return (
              <Text weight="bold" element="div">
                /{name?.split(':')?.join('/')}
              </Text>
            )
          }}
        />
        <Column
          title={t('Read')}
          className="mk-table-cell--bold"
          dataIndex="read"
          render={(read, row) => {
            if (row.actions?.includes('read')) {
              return (
                <Checkbox
                  weight="bold"
                  element="div"
                  checked={read}
                  onChange={(e) =>
                    onChangePermission(row.permissionKeyRead, e.target.checked)
                  }
                />
              )
            }

            return null
          }}
        />
        <Column
          title={t('Write')}
          className="mk-table-cell--bold"
          dataIndex="write"
          render={(write, row) => {
            if (row.actions?.includes('write')) {
              return (
                <Checkbox
                  weight="bold"
                  element="div"
                  checked={write}
                  onChange={(e) => {
                    onChangePermission(row.permissionKeyWrite, e.target.checked)
                    if (e.target.checked && row.actions?.includes('read')) {
                      onChangePermission(row.permissionKeyRead, true)
                    }
                  }}
                  key={`${row.actions.join('-')}-${String(write)}`}
                />
              )
            }

            return null
          }}
        />
      </Table>
    </div>
  )
}

export default observer(AccessPermission)
